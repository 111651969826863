import MultiSelect from '@LoopKitchen/loop-ui/FilterSelect/views/MultiSelect'
import IosSwitch from '@LoopKitchen/loop-ui/Switch/IosSwitch'
import {
  AutoAwesome,
  DateRange,
  Help,
  Insights,
  PsychologyAlt,
  Storefront,
  SvgIconComponent,
  Troubleshoot
} from '@mui/icons-material'
import { Box, Button, Chip, CircularProgress, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import ConfirmDialog from 'src/components/ConfirmDialog'
import CustomMaterialTableWrapper from 'src/components/CustomMaterialTableWrapper/CustomMaterialTableWrapper'
import WarningDiamondIcon from 'src/components/Icon/WarningDiamondIcon'
import PlatformLogo from 'src/components/PlatformLogo'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { DateRangeType } from 'src/context/NewFilterContext/utils/FilterGraph'
import { useSnackData } from 'src/context/SnackContext'
import { CampaignAudience, CampaignDetailPaginatedV3, CampaignDetailPaginatedV3Paginated, DefaultService } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { downloadCsv, formatCurrency, formatNumber } from 'src/utils/functions'
import { pluralize } from 'src/utils/functions/pluralize'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import LocalStorage from '../../../MarketingCampaignManagement/utils/LocalStorage'
import Typo from "@LoopKitchen/loop-ui/Typo";

export const CampaignFlagProperties = {
  low_performing: {
    primary: '#E52E2E',
    secondary: '#FFE5E5',
    title: 'Low Performing',
    icon: <WarningDiamondIcon sx={{ color: '#E52E2E', fontSize: '14px' }} />
  },
  recently_added: {
    primary: '#004DE5',
    secondary: '#E0EBFF',
    title: 'New',
    icon: <DateRange sx={{ color: '#004DE5', fontSize: '14px' }} />
  }
}

interface CampaignTableProps {
  period?: DateRangeType
  showRecentlyAdded?: boolean
  setShowRecentlyAdded?: (val: boolean) => void
  setShowAllCampaigns?: (val: boolean) => void
  onCampaignRowClick: (campaignDetails: CampaignDetailPaginatedV3) => void
}

enum METRICS {
  SALES = 'Sales',
  SPEND = 'Spend',
  GROSS_MARGIN = 'Gross Margin',
  DOUBLE_DIP_RATE = 'Double Dip Rate',
  EFF_ROI = 'Effective ROI',
  COFUND = 'Cofunding',
  BUDGET = 'Budget'
}

const metricFieldMap: Record<METRICS, string | null> = {
  Sales: 'sales',
  Spend: 'cost',
  'Double Dip Rate': 'attr_multi_campaign_orders_perc',
  'Gross Margin': 'attr_payout_perc',
  'Effective ROI': 'attr_eff_roi',
  Cofunding: 'attr_cofund',
  Budget: 'budget'
}

interface IconTooltipProps {
  tooltip: string;
  position: 'left' | 'right' | 'top' | 'bottom';
  icon: SvgIconComponent
}


const IconTooltip = ({ tooltip, position, icon: Icon }) => {
  return (
    <Tooltip
      title={tooltip}
      arrow
      placement={position}
      componentsProps={{
        tooltip: {
          style: {
            color: 'white',
            fontSize: 12,
            fontWeight: 400,
            maxWidth: 300,
            padding: '10px',
            backgroundColor: 'rgba(0, 0, 0, 1)'
          }
        },
        arrow: {
          style: {
            color: 'black'
          }
        }
      }}>
      <Box
        sx={{
          fontSize: '18px',
          color: 'black',
          borderRadius: '2px',
          bgcolor: '#E8E8E8',
          width: '25px',
          height: '25px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Icon
          fontSize="inherit"
          color="inherit"
        />
      </Box>
    </Tooltip>
  );
};


export default function CampaignTable({ period = null, onCampaignRowClick }: CampaignTableProps) {
  const { headerHeightPx } = useLayoutDimension()
  const [data, setData] = React.useState<CampaignDetailPaginatedV3Paginated>(null)
  const [loading, setLoading] = React.useState(false)
  const [orderBy, setOrderBy] = React.useState<string>('cost')
  const [ascending, setAscending] = React.useState<boolean>(false)
  const [showLowPerformingCampaign, setShowLowPerformingCampaign] = React.useState(false)
  
  const selectedMetrics = [METRICS.SALES, METRICS.SPEND, METRICS.COFUND, METRICS.BUDGET, METRICS.GROSS_MARGIN, METRICS.DOUBLE_DIP_RATE, METRICS.EFF_ROI]
  const [searchText, setSearchText] = React.useState<string>('')
  
  const campaignTypes = ['Bogo', 'Discounted_item', 'Flat', 'Free_delivery', 'Free_item', 'Ad', 'Other']
  const [selectedCampaignType, setSelectedCampaignType] = React.useState<string[]>(campaignTypes)
  const { getFiltersV2, getFilters, lastDate } = useFilter()
  const { setDownloadSnack, openSuccess } = useSnackData()
  const { handleError } = useErrorData()
  
  const tableData = React.useMemo(() => {
    return get(data, 'data', [] as typeof data.data)
  }, [data])
  
  const getCSVString = async () => {
    const filterObj = {
      limit: 100,
      campaign_flag: showLowPerformingCampaign ? 'low_performing' : undefined,
      campaignType_in: selectedCampaignType.join('|') || undefined,
      ...getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
    }
    
    if (period) {
      filterObj['start_date_in'] = period.start.format('YYYY-MM-DD')
      filterObj['end_date_in'] = period.end.format('YYYY-MM-DD')
    }
    // if (showRecentlyAdded) {
    //   filterObj['start_date_in'] = moment(lastDate).subtract(1, 'month').format('YYYY-MM-DD')
    //   filterObj['end_date_in'] = moment(lastDate).format('YYYY-MM-DD')
    // }
    
    return await DefaultService.exportCampaignDetailV3ApiExportCampaignDetailV3Post(filterObj)
  }
  
  const exportCSV = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      const res = await getCSVString()
      downloadCsv('campaign.csv', res)
      setDownloadSnack({ status: 'complete' })
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      handleError(err.message)
    }
  }
  
  const getData = async (
    obj: { offset: number; order_by?: string; ascending?: boolean; limit?: number },
    callback: (data: CampaignDetailPaginatedV3Paginated) => void
  ) => {
    setLoading(true)
    try {
      const filterObj = {
        limit: 100,
        campaign_flag: showLowPerformingCampaign ? 'low_performing' : undefined,
        campaignType_in: selectedCampaignType.join('|') || undefined,
        search_term: searchText,
        ...obj,
        ...getFiltersV2(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'], true)
      }
      
      if (period) {
        filterObj['start_date_in'] = period.start.format('YYYY-MM-DD')
        filterObj['end_date_in'] = period.end.format('YYYY-MM-DD')
      }
      // if (showRecentlyAdded) {
      //   filterObj['start_date_in'] = moment(lastDate).subtract(1, 'month').format('YYYY-MM-DD')
      //   filterObj['end_date_in'] = moment(lastDate).format('YYYY-MM-DD')
      // }
      
      const res = await DefaultService.callCampaignDetailV3ApiPaginatedCampaignDetailV3Post(filterObj, true)
      callback(res)
    } catch (err) {
      handleError(err.message)
    } finally {
      setLoading(false)
    }
  }
  

  
  React.useEffect(() => {
    getData({ offset: 0, order_by: orderBy, ascending }, (res) => {
      setData(res)
    })
  }, [
    orderBy,
    ascending,
    showLowPerformingCampaign,
    selectedCampaignType,
    searchText,
    period,
    ...getFilters(['vb_name', 'chain', 'b_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])
  ])
  
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
      >
        {/* <Box
          display="flex"
          alignItems="center"
          gap={3}>


          {!period && (
              <FormControlLabel
                value="recently_added"
                control={
                  <IosSwitch
                    icon={
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          backgroundColor: '#fff'
                        }}
                      />
                    }
                    checkedIcon={
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          backgroundColor: '#fff'
                        }}
                      />
                    }
                    sx={{ height: '18px', width: '34px' }}
                  />
                }
                label={
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="#696C80"
                    sx={{ ml: 1 }}>
                    Recently Added (Last 30 days)
                  </Typography>
                }
                checked={showRecentlyAdded}
                onChange={(e, checked) => {
                  setShowRecentlyAdded(checked)
                }}
                sx={{ m: 0 }}
              />
          )}
        </Box> */}
        
        <CustomMaterialTableWrapper
          data={tableData}
          isLoading={loading}
          titleComp={
            <Stack
              direction="row"
              alignItems="center"
              gap="16px">
              <MultiSelect
                value={selectedCampaignType}
                disableSort
                disableSearch
                onChange={(val) => {
                  setSelectedCampaignType(val)
                }}
                options={[
                  ...campaignTypes.map((e) => {
                    return {
                      label: e.replaceAll('_', ' '),
                      value: e
                    }
                  })
                ]}
                selectButtonSx={{
                  height: '40px',
                  width: '140px',
                  bgcolor: '#F6f6f6',
                  color: '#000',
                  '&:hover': {
                    bgcolor: '#EEEEEE'
                  },
                  '& p': {
                    color: '#000'
                  },
                  borderRadius: '4px',
                  px: '16px',
                  py: '5px'
                }}
              />
              <FormControlLabel
                value="low_performing"
                control={
                  <IosSwitch
                    icon={
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          backgroundColor: '#fff'
                        }}
                      />
                    }
                    checkedIcon={
                      <Box
                        sx={{
                          width: 14,
                          height: 14,
                          borderRadius: '50%',
                          backgroundColor: '#fff'
                        }}
                      />
                    }
                    sx={{ height: '18px', width: '34px' }}
                  />
                }
                label={
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="#696C80"
                    sx={{ ml: 1 }}>
                    Low Performing Campaigns
                  </Typography>
                }
                checked={showLowPerformingCampaign}
                onChange={(e, checked) => {
                  setShowLowPerformingCampaign(checked)
                }}
                sx={{ m: 0 }}
              />
              <Box sx={{ flexGrow: 1 }} /> {/* This pushes the tooltip to the right */}
              <Typography
                fontSize="12px"
                fontWeight={600}
                color="#696C80"
              >
                Metric Source
              </Typography>
              <IconTooltip
                tooltip={'Measured using orders our system could smartly match from order-level financial reports against merchant-reported daily marketing data.'}
                icon={Insights}
                position={'bottom'}
              />
              <IconTooltip
                tooltip={'Measured using merchant-reported daily marketing data'}
                icon={Storefront}
                position={'bottom'}
              />
            </Stack>
          }
          columns={[
            {
              title: 'Campaign',
              field: 'campaignName',
              cellStyle: { minWidth: '250px' },
              render: (data) => {
                return (
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      gap={2}
                      p={1}
                      bgcolor="#F4F4F4"
                      borderRadius="4px"
                      minWidth={270}
                      // sx={{
                      //   cursor: 'pointer',
                      //   transition: '0.3s ease',
                      //   '&:hover': {
                      //     bgcolor: '#E6E6E6'
                      //   }
                      // }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        gap={1}>
                        <Box
                          p={'2px'}
                          bgcolor="#fff">
                          <PlatformLogo
                            shortImg
                            platformName={get(data, 'vb_platform', '')}
                            width="24px"
                            height="26px"
                            boxSx={{
                              display: 'flex',
                              alignItems: 'center',
                              flex: 'none'
                            }}
                          />
                        </Box>
                        
                        <Typography
                          fontSize="13px"
                          fontWeight={600}
                          color="#000"
                          lineHeight="initial">
                          {get(data, 'campaignName', '')}
                        </Typography>
                      </Box>
                      
                      {/* <ChevronRight sx={{ fontSize: '20px', color: '#000' }} /> */}
                    </Box>
                    
                    <Box
                      display="flex"
                      alignItems="center"
                      flexWrap="wrap"
                      gap={1}
                      mt={1}>
                      {data.audience !== CampaignAudience.UNKNOWN && (
                        <Tooltip title={`Audience`}>
                          <Chip
                            sx={{ borderRadius: '12px', fontSize: '12px', fontWeight: 500, pt: 0 }}
                            label={data.audience}
                          />
                        </Tooltip>
                      )}
                      {get(data, 'campaign_flag', '') === 'low_performing' && (
                        <Box
                          display="flex"
                          alignItems="center"
                          padding="10px 10px"
                          borderRadius="12px"
                          width="fit-content"
                          bgcolor={CampaignFlagProperties['low_performing'].secondary}
                          gap={0.5}>
                          {CampaignFlagProperties['low_performing'].icon}
                          <Typography
                            color={CampaignFlagProperties['low_performing'].primary}
                            fontWeight={500}
                            fontSize="11px"
                            lineHeight="initial"
                            whiteSpace="nowrap">
                            {CampaignFlagProperties['low_performing'].title}
                          </Typography>
                        </Box>
                      )}
                      
                      {moment(get(data, 'startTime', '')).isSameOrAfter(moment(lastDate).subtract(1, 'month')) && (
                        <Box
                          display="flex"
                          alignItems="center"
                          padding="4px 10px"
                          borderRadius="12px"
                          width="fit-content"
                          bgcolor={CampaignFlagProperties['recently_added'].secondary}
                          gap={0.5}>
                          {CampaignFlagProperties['recently_added'].icon}
                          <Typography
                            color={CampaignFlagProperties['recently_added'].primary}
                            fontWeight={500}
                            fontSize="11px"
                            lineHeight="initial"
                            whiteSpace="nowrap">
                            {CampaignFlagProperties['recently_added'].title}
                          </Typography>
                        </Box>
                      )}
                      
                      {get(data, 'startTime', null) && (
                        <Typography
                          fontSize="12px"
                          color="#000">
                          From {moment(get(data, 'startTime', '')).format('DD MMM YYYY')}
                          {` till ${!data.endTime || data.endTime === null || data.endTime === '' || Math.abs(moment().diff(moment(get(data, 'endTime', '')), 'years')) >= 90 ? 'Indefinite' : moment(get(data, 'endTime', '')).format('DD MMM YYYY')}`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                )
              }
            },
            {
              title: filterNames.vb_name,
              field: 'vb_name',
              cellStyle: { minWidth: '200px' },
              render: (data) => {
                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={0.5}>
                    <Typography
                      fontSize="12px"
                      fontWeight={600}
                      color="#000">
                      {get(data, 'vb_name', '')}
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color="#000">
                      {get(data, 'b_name', '')}
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color="#0008">
                      {get(data, 'vb_address', '')}
                    </Typography>
                  </Box>
                )
              }
            },
            {
              title: (
                <Tooltip title="Total marketing sales reported by merchant platform">
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="black"
                    display="flex"
                    alignItems="center">
                    <Storefront sx={{ fontSize: 16, mr: '8px' }} />
                    Sales
                  </Typography>
                </Tooltip>
              
              ) as any,
              field: metricFieldMap[METRICS.SALES],
              hideInExport: true,
              alignType: 'currency',
              cellStyle: {
                textAlign: 'center',
              },
              render: (data) => {
                return (
                  <Box>
                    <Typography
                      fontSize="12px"
                      color="#0E8C43">
                      {formatCurrency(get(data, metricFieldMap[METRICS.SALES], 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Typography
                      fontSize="12px"
                      color="#A0A0A0">
                      {get(data, 'orders', 0)} {pluralize('order', get(data, 'orders', 0))}
                    </Typography>
                  </Box>
                )
              }
            },
            {
              title: (

                  <Tooltip title="Total marketing spend reported by merchant platform.">
                    <Typography
                      fontSize="12px"
                      fontWeight={600}
                      color="black"
                      display="flex"
                      alignItems="center">
                      <Storefront sx={{ fontSize: 16, mr: '8px' }} />
                      Spend
                    </Typography>
                  </Tooltip>
              
              ) as any,
              field: metricFieldMap[METRICS.SPEND],
              hidden: !selectedMetrics.includes(METRICS.SPEND),
              hideInExport: true,
              alignType: 'currency',
              cellStyle: {
                textAlign: 'center',
              },
              render: (data) => {
                return (
                  <Typography
                    fontSize="12px"
                    color="#000">
                    {formatCurrency(get(data, metricFieldMap[METRICS.SPEND], 0), { maxFractionDigits: 2 })}
                  </Typography>
                )
              }
            },
            
            {
              title: (
                
                <Tooltip title="The ratio of Effective Sales to Effective Marketing Spend, calculated after deducting third-party expenses from marketing driven sales and co-funding from marketing spend.">
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="black"
                    display="flex"
                    alignItems="center">
                    <Insights sx={{ fontSize: 16, mr: '8px' }} />
                    Effective ROI
                  </Typography>
                </Tooltip>
                
              ) as any,
              field: metricFieldMap[METRICS.EFF_ROI],
              hidden: !selectedMetrics.includes(METRICS.EFF_ROI),
              hideInExport: true,
              sortable: true,
              cellStyle: {
                textAlign: 'center',
              },
              render: (data) => {
                return (
                  <Typography
                    fontSize="12px"
                    color="#000">
                    {formatNumber(get(data, metricFieldMap[METRICS.EFF_ROI], 0), { maxFractionDigits: 2 })}
                  </Typography>
                )
              }
            },
            {
              title: (
                <Tooltip title="Percentage of orders attributed to this campaign having other marketing spends.">
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="black"
                    display="flex"
                    alignItems="center">
                    <Insights sx={{ fontSize: 16, mr: '8px' }} />
                    Double Dip Rate
                  </Typography>
                </Tooltip>
              ) as any,
              field: metricFieldMap[METRICS.DOUBLE_DIP_RATE],
              hidden: !selectedMetrics.includes(METRICS.DOUBLE_DIP_RATE),
              hideInExport: true,
              cellStyle: {
                textAlign: 'center',
              },
              render: (data) => {
                return (
                  <Typography
                    fontSize="12px"
                    color="#000">
                    {formatNumber(get(data, metricFieldMap[METRICS.DOUBLE_DIP_RATE], 0), { maxFractionDigits: 2 }) + '%'}
                  </Typography>
                )
              }
            },
            {
              title: (
                <Tooltip title="Net Payout divided by Sales">
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="black"
                    display="flex"
                    alignItems="center">
                    <Insights sx={{ fontSize: 16, mr: '8px' }} />
                    Gross Margin
                  </Typography>
                </Tooltip>
              ) as any,
              field: metricFieldMap[METRICS.GROSS_MARGIN],
              hidden: !selectedMetrics.includes(METRICS.GROSS_MARGIN),
              hideInExport: true,
              cellStyle: {
                textAlign: 'center',
              },
              render: (data) => {
                return (
                  <Typography
                    fontSize="12px"
                    color="#000">
                    {formatNumber(get(data, metricFieldMap[METRICS.GROSS_MARGIN], 0), { maxFractionDigits: 2 }) + '%'}
                  </Typography>
                )
              }
            },
            
            {
              title: (
                <Tooltip title="Promotional co-funding received from matched orders.">
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="black"
                    display="flex"
                    alignItems="center">
                    <Insights sx={{ fontSize: 16, mr: '8px' }} />
                    Cofunding
                  </Typography>
                </Tooltip>
              ) as any,
              field: metricFieldMap[METRICS.COFUND],
              hidden: !selectedMetrics.includes(METRICS.COFUND),
              hideInExport: true,
              cellStyle: {
                textAlign: 'center',
              },
              render: (data) => {
                return (
                  <Typography
                    fontSize="12px"
                    color="#000">
                    {formatCurrency(get(data, metricFieldMap[METRICS.COFUND], 0), { maxFractionDigits: 2 })}
                  </Typography>
                )
              }
            },

            {
              title: (
                <Tooltip title="Target marketing spend allocated per week for this campaign">
                  <Typography
                    fontSize="12px"
                    fontWeight={600}
                    color="black"
                    display="flex"
                    alignItems="center">
                    <Storefront sx={{ fontSize: 16, mr: '8px' }} />
                    {METRICS.BUDGET}
                  </Typography>
                </Tooltip>
              ) as any,
              field: metricFieldMap[METRICS.BUDGET],
              hidden: !selectedMetrics.includes(METRICS.BUDGET),
              hideInExport: true,
              cellStyle: {
                textAlign: 'center',
              },
              render: (data) => {
                return (
                  <Typography
                    fontSize="12px"
                    color="#000">
                    {data.budget && data.budget > 0 ? formatCurrency(get(data, metricFieldMap[METRICS.BUDGET], 0), { maxFractionDigits: 2 }) : 'Uncapped'}
                  </Typography>
                )
              }
            },
          ]}
          options={{
            stickyHeader: period ? '-17px' : headerHeightPx,
            export: true,
            extraExportColumns: [
              {
                title: filterNames.b_name,
                field: 'b_name'
              },
              {
                title: filterNames.vb_address,
                field: 'vb_address'
              },
              {
                title: filterNames.vb_platform,
                field: 'vb_platform'
              },
              {
                title: 'Total Sales',
                field: 'sales'
              },
              {
                title: 'Attributed Sales',
                field: 'attr_sales'
              },
              {
                title: 'Total Spend',
                field: 'cost'
              },
              {
                title: 'Attributed Spend',
                field: 'attr_spend'
              },
              {
                title: 'Total Orders',
                field: 'orders'
              },
              {
                title: 'Attributed Orders',
                field: 'attr_orders'
              },
              {
                title: '3P ROI',
                field: 'roi'
              },
              {
                title: 'Attributed Effective ROI',
                field: 'attr_eff_roi'
              },
              {
                title: 'Attributed Cofund',
                field: 'attr_cofund'
              },
              {
                title: 'Attributed Payout',
                field: 'attr_payout'
              },
              {
                title: 'Attributed Other Marketing Spend',
                field: 'attr_other_spend'
              },
              {
                title: 'Double Dip Rate',
                field: 'attr_multi_campaign_orders_perc'
              },
              {
                title: 'Cohort',
                field: 'audience'
              },
              {
                title: 'Unattributed Sales',
                field: 'unattr_sales'
              },
              {
                title: 'Unattributed Spend',
                field: 'unattr_spend'
              },
              {
                title: 'Unattributed Orders',
                field: 'unattr_orders'
              },
            ],
            search: true,
            sortable: true,
            showOrderBy: true,
            initialOrderBy: ascending ? 'ascending' : 'descending',
            pagination: true,
            totalPaginatedDataLength: get(data, 'max_rows', undefined),
            debounceMilliseconds: 1000
          }}
          onRowClick={(data) => onCampaignRowClick(data)}
          onExportModifyCSV={getCSVString}
          onExportCSV={() => {
            exportCSV()
          }}
          onSearchChange={(searchText) => {
            setSearchText(searchText)
          }}
          onOrderByChange={(type) => {
            setAscending(type === 'ascending')
          }}
          onSortByChange={(option) => {
            setOrderBy(option.value)
          }}
          onLastPage={() => {
            const nextOffset = get(data, 'next_offset', undefined)
            if (nextOffset) {
              getData({ offset: nextOffset, order_by: orderBy, ascending }, (res) => {
                setData((prev) => ({
                  ...res,
                  data: [...get(prev, 'data', []), ...get(res, 'data', [])]
                }))
              })
            }
          }}
        />
      </Box>
    </>
  )
}
