import { Box, Typography } from '@mui/material'
import PlatformLogo from 'src/components/PlatformLogo'
import { get } from 'src/utils/config/lodashUtils'
import CustomChip from '../../components/CustomChip'

interface PlatformStyledCompProps {
  data: object
  active: boolean
}

export default function PlatformStyledComp(props: PlatformStyledCompProps) {
  const { data, active } = props
  const name = get(data, 'vb_platform', null)

  if (!name) {
    return <></>
  }

  return (
    <CustomChip
      active={active}
      showRightArrow>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '7px'
        }}>
        <PlatformLogo
          shortImg
          platformName={name}
          height="16px"
          width="16px"
          boxSx={{ minWidth: '16px', maxWidth: '16px' }}
        />
        <Typography variant="subtitle2">{name}</Typography>
      </Box>
    </CustomChip>
  )
}
