import ExportTableComp from '@LoopKitchen/loop-ui/CustomMaterialTable/components/ExportTableComp'
import TableButton from '@LoopKitchen/loop-ui/CustomMaterialTable/components/TableButton'
import TuneIcon from '@mui/icons-material/Tune'
import { Divider, Stack } from '@mui/material'

interface CustomDownloadBtnProps {
  onNormalExport?: () => void
  onModifyExport?: () => void
  loading?: boolean
}

export default function CustomDownloadBtn(props: CustomDownloadBtnProps) {
  const { onNormalExport, onModifyExport, loading } = props

  return (
    <>
      <Stack
        direction="row"
        alignItems={'center'}
        gap="10px"
        sx={{ bgcolor: '#F6F6F6' }}>
        <ExportTableComp
          onExportCSV={onNormalExport}
          loadingExport={loading}
        />
        {onModifyExport && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ my: '10px' }}
            />
            <TableButton
              onClick={onModifyExport}
              disabled={loading}>
              <TuneIcon sx={{ fontSize: '20px' }} />
            </TableButton>
          </>
        )}
      </Stack>
    </>
  )
}
