import { OrderByType } from '@LoopKitchen/loop-ui/CustomMaterialTable'
import ExportTableComp from '@LoopKitchen/loop-ui/CustomMaterialTable/components/ExportTableComp'
import PaginationTableComp from '@LoopKitchen/loop-ui/CustomMaterialTable/components/PaginationTableComp'
import SearchTableComp from '@LoopKitchen/loop-ui/CustomMaterialTable/components/SearchTableComp'
import SortByComp from '@LoopKitchen/loop-ui/CustomMaterialTable/components/SortByComp'
import MultiSelect from '@LoopKitchen/loop-ui/FilterSelect/views/MultiSelect'
import IosSwitch from '@LoopKitchen/loop-ui/Switch/IosSwitch'
import { Box, FormControlLabel } from '@mui/material'
import { CsvBuilder } from 'filefy'
import moment from 'moment/moment'
import { useEffect, useMemo, useState } from 'react'
import { useAuth } from 'src/context/AuthContext'
import { AccountingCadenceConfig, AccountingPushHistoryFilters, DefaultService } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { downloadFailedSlackAlert, downloadSuccessSlackAlert } from 'src/utils/functions/slackSupport'
import processCSV from 'src/utils/workers/functions/processCSV'
import Page from '../../../components/mui/Page'
import { useErrorData } from '../../../context/ErrorContext'
import { useFilter } from '../../../context/FilterContext'
import { useSnackData } from '../../../context/SnackContext'
import usePostHogHook from '../../../utils/hooks/usePostHogHook'
import PushHistoryItem from './components/PushHistoryItem'

export enum JournalPushStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  IN_PROGRESS = 'IN_PROGRESS',
  SKIPPED = 'SKIPPED'
}

export const JournalPushStatusMap = {
  [JournalPushStatus.SUCCESS]: {
    name: 'Success',
    color: '#0E8C43',
    secondaryColor: '#0E8C4317'
  },
  [JournalPushStatus.FAILURE]: {
    name: 'Failure',
    color: '#FF0C00',
    secondaryColor: '#FF000017'
  },
  [JournalPushStatus.IN_PROGRESS]: {
    name: 'In Progress',
    color: '#0223f5',
    secondaryColor: '#0223f517'
  },
  [JournalPushStatus.SKIPPED]: {
    name: 'Skipped',
    color: '#e8b305',
    secondaryColor: '#e8b30517'
  }
}

const sortOptions = [
  {
    label: 'Push Date',
    value: 'updated_at'
  },
  {
    label: 'Pushed By',
    value: 'push_user'
  }
]

interface AccountingPushLog {
  created_at: string
  updated_at: string
  id: number
  accounting_type: string | null
  start_date: string
  end_date: string
  chain: string
  platforms: string
  b_name: string
  b_name_id: string
  status: JournalPushStatus
  error: string
  push_timestamp: string | null
  push_run_id: string | null
  push_user: string | null
  override_push_overlap: boolean | null
  override_tests: boolean | null
  dummy: boolean
  latest_credit: number | null
  latest_debit: number | null
  diff_calculated_time: string | null
  company_id: number | null
  template_id: number
  cadence_id: number
  csv_link: string | null
  debit: number | null
  credit: number | null
  resolved: boolean | null
  resolution_comment: string | null
  company_name: string | null
  integration_type: string | null
}

type SortByOptionType = {
  label: string
  value: string
}

export type PushHistoryData = {
  date: string
  cadence_id: string | number
  start_date: string
  end_date: string
  push_user: string
  logs: AccountingPushLog[]
}

export default function AccountingHistory() {
  const { getFilters, getFiltersV2 } = useFilter()
  const { handleError } = useErrorData()
  const { currentUser } = useAuth()
  const { openError, setDownloadSnack } = useSnackData()
  const { trackPostHogDownload } = usePostHogHook()

  const [loading, setLoading] = useState<boolean>(true)
  const [pushHistoryResponse, setPushHistoryResponse] = useState<AccountingPushLog[]>([])
  const [selectedStatus, setSelectedStatus] = useState<JournalPushStatus[]>(Object.values(JournalPushStatus))
  const [dummy, setDummy] = useState(false)
  const [sortBy, setSortBy] = useState<SortByOptionType>(sortOptions[0])
  const [orderBy, setOrderBy] = useState<OrderByType>('descending')
  const [searchText, setSearchText] = useState<string>('')
  const [cadences, setCadences] = useState<AccountingCadenceConfig[]>([])
  const [cadencesLoading, setCadencesLoading] = useState<boolean>(true)
  const [pageIndex, setPageIndex] = useState<{ startIndex: number; endIndex: number }>({
    startIndex: 0,
    endIndex: 0
  })

  const filteredPushHistoryData = useMemo(() => {
    if (!pushHistoryResponse || loading) return []

    const result = pushHistoryResponse.filter((log) => {
      const statusMatch = selectedStatus.length === 0 || selectedStatus.includes(log.status as JournalPushStatus)
      const searchTextLower = searchText.toLowerCase()
      const searchMatch =
        searchText === '' ||
        [
          log.b_name,
          log.company_name,
          log.integration_type,
          log.accounting_type,
          log.error,
          log.start_date,
          log.end_date,
          log.status,
          log.push_user,
          log.push_timestamp,
          log.resolution_comment,
          log.platforms
        ].some((field) => typeof field === 'string' && field?.toLowerCase().includes(searchTextLower))

      return statusMatch && searchMatch
    })

    result.sort((a, b) => {
      const orderMultiplier = orderBy === 'descending' ? 1 : -1

      if (sortBy.value === 'updated_at') {
        return moment(a.updated_at).isBefore(moment(b.updated_at)) ? orderMultiplier : -orderMultiplier
      } else if (sortBy.value === 'push_user') {
        const aPushUser = a.push_user === 'Loop Auto Push' ? 'AAAAA' : a.push_user
        const bPushUser = b.push_user === 'Loop Auto Push' ? 'AAAAA' : b.push_user
        return aPushUser?.localeCompare(bPushUser) * orderMultiplier
      } else {
        return 0
      }
    })

    const data: PushHistoryData[] = result.reduce((acc, log) => {
      const date = moment.utc(log.created_at).local().format('MMM DD, YYYY, HH:mm A')
      const cadence_id = log.cadence_id
      const start_date = log.start_date
      const end_date = log.end_date
      const push_user = log.push_user
      const push_run_id = log.push_run_id

      const existingData = acc.find(
        (d) => push_run_id === d.push_run_id
      )

      if (existingData) {
        existingData.logs.push(log)
      } else {
        acc.push({
          date,
          push_run_id,
          cadence_id,
          start_date,
          end_date,
          push_user,
          logs: [log]
        })
      }
      return acc
    }, [])

    setPageIndex({ startIndex: 0, endIndex: (data?.length || 0) > 10 ? 10 : data?.length })

    return data
  }, [pushHistoryResponse, loading, selectedStatus, searchText, sortBy, orderBy])

  const getData = async () => {
    setLoading(true)
    try {
      const filtersForQuery = getFiltersV2(['chain', 'b_name', 'b_name_id', 'start_date', 'end_date'], true)
      const requestBody = {
        chain: filtersForQuery.chain_in,
        b_name: filtersForQuery.b_name_in,
        b_name_id: filtersForQuery.b_name_ids_in,
        start_date: filtersForQuery.start_date_in,
        end_date: filtersForQuery.end_date_in,
        dummy: dummy
      } as AccountingPushHistoryFilters

      const result: AccountingPushLog[] = await DefaultService.getPushHistoryAccountingJournalEntriesPushHistoryPost(
        requestBody,
        false,
        !window.location.href.endsWith('internal')
      )

      setPushHistoryResponse(result)
    } catch (err) {
      handleError(err.message)
    }
    setLoading(false)
  }

  const getCSVString = async () => {
    const filtersForQuery = getFiltersV2(['chain', 'b_name', 'b_name_id', 'start_date', 'end_date'], true)
    const requestBody = {
      chain: filtersForQuery.chain_in,
      platforms: filtersForQuery.vb_platform_in,
      b_name: filtersForQuery.b_name_in,
      b_name_id: filtersForQuery.b_name_ids_in,
      start_date: filtersForQuery.start_date_in,
      end_date: filtersForQuery.end_date_in,
      dummy: dummy
    } as AccountingPushHistoryFilters

    const csvData = await DefaultService.getPushHistoryAccountingJournalEntriesPushHistoryPost(requestBody, true, !window.location.href.endsWith('internal'))

    const columns = [
      {
        title: filterNames.b_name,
        field: 'b_name'
      },
      {
        title: filterNames.vb_platform,
        field: 'platforms'
      },
      {
        title: 'Created At',
        field: 'created_at'
      },
      {
        title: 'Start Date',
        field: 'start_date'
      },
      {
        title: 'End Date',
        field: 'end_date'
      },
      {
        title: 'Status',
        field: 'status'
      },
      {
        title: 'Error',
        field: 'error'
      },
      {
        title: 'Push Timestamp',
        field: 'push_timestamp'
      },
      {
        title: 'Push User',
        field: 'push_user'
      },
      {
        title: 'Dummy Push',
        field: 'dummy'
      },
      {
        title: 'Pre-push validation skipped',
        field: 'override_tests'
      },
      {
        title: 'Pushed with overlap',
        field: 'override_push_overlap'
      },
      {
        title: 'Company Name',
        field: 'company_name'
      },
      {
        title: 'Integration Type',
        field: 'integration_type'
      }
    ]

    let csvString = null

    processCSV({
      csvData,
      columns,
      onSuccess: (data) => {
        const fileName = 'Push History.csv'
        const builder = new CsvBuilder(fileName)
        builder.setColumns(data.headers)
        builder.addRows(data.rows)
        builder.exportFile()
      },
      onError: (err) => {
        openError(err)
      }
    })
    return csvString
  }

  const onExportCSV = async () => {
    setDownloadSnack({ status: 'start' })
    try {
      await getCSVString()

      setDownloadSnack({ status: 'complete' })
      trackPostHogDownload({
        fileName: 'push_history',
        type: 'CSV'
      })
      downloadSuccessSlackAlert({ title: 'JOURNAL PUSHES', apiUrl: '/accounting/journal_entries/push/history' })
    } catch (err) {
      setDownloadSnack({ status: 'close' })
      openError(err.message)
      downloadFailedSlackAlert({ err, apiUrl: '/accounting/journal_entries/push/history' })
    }
  }

  const getConfigs = async () => {
    setCadencesLoading(true)
    try {
      const res = await DefaultService.getAccountingCadenceConfigsAccountingConfigurationsGetGet(currentUser.org)

      if (res.configs) {
        setCadences(res.configs.sort((a, b) => a.id - b.id))
      }
    } catch (err) {
      handleError(err)
    }
    setCadencesLoading(false)
  }

  useEffect(() => {
    getData()
  }, [...getFilters(['chain', 'b_name', 'start_date', 'end_date']), dummy])

  useEffect(() => {
    getConfigs()
  }, [currentUser.org])

  return (
    <Page
      title="Journal Entries Push History"
      filterProps={{
        hideFilterKeys: ['vb_name', 'am_name', 'vb_platform'],
        hideResetButton: true,
        customMaxDate: moment().format('YYYY-MM-DD'),
        showExtraFiltersAtEnd: true,
        extraFilters: [
          <MultiSelect
            label="Status"
            selectButtonSx={{
              p: '12px 32px 12px 8px'
            }}
            disableScrollLock
            options={Object.values(JournalPushStatus).map((s) => ({ label: JournalPushStatusMap[s].name, value: s }))}
            value={selectedStatus}
            onChange={(newValue) => {
              if (Array.isArray(newValue)) {
                setSelectedStatus(newValue)
              }
            }}
          />
        ]
      }}>
      <Box sx={{ px: 3, py: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box
          display="flex"
          justifyContent="flex-end">
          <FormControlLabel
            label="Test Pushes"
            sx={{
              gap: 1,
              '& .MuiTypography-root': {
                fontSize: '14px',
                color: '#000'
              }
            }}
            control={
              <IosSwitch
                checked={dummy}
                onChange={(event, checked) => setDummy(checked)}
                sx={{ height: '18px', width: '34px' }}
                icon={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 14,
                      height: 14,
                      borderRadius: '50%',
                      backgroundColor: '#fff'
                    }}
                  />
                }
                checkedIcon={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 14,
                      height: 14,
                      borderRadius: '50%',
                      backgroundColor: '#fff'
                    }}
                  />
                }
              />
            }
          />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          gap={1}
          width="100%">
          <SearchTableComp
            onChange={(searchText) => setSearchText(searchText)}
            debounceMilliseconds={1000}
          />
          <SortByComp
            options={sortOptions}
            showOrderBy
            initialOption={sortBy}
            onChange={(selectedOption) => setSortBy(selectedOption)}
            initialOrderBy={orderBy}
            onOrderByChange={(type) => setOrderBy(type)}
          />
          <ExportTableComp onExportCSV={() => onExportCSV()} />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap={1.5}>
          {loading
            ? [...Array(10)].map((_, index) => (
                <PushHistoryItem
                  key={index}
                  data={null}
                  loading={loading}
                  cadences={cadences}
                  cadencesLoading={cadencesLoading}
                />
              ))
            : filteredPushHistoryData?.slice(pageIndex.startIndex, pageIndex.endIndex)?.map((data, index) => (
                <PushHistoryItem
                  key={
                    get(data, 'date', '') +
                    get(data, 'cadence_id', '') +
                    get(data, 'start_date', '') +
                    get(data, 'end_date', '') +
                    get(data, 'logs.length', '') +
                    index
                  }
                  data={data}
                  loading={loading}
                  cadences={cadences}
                  cadencesLoading={cadencesLoading}
                />
              ))}
        </Box>

        <Box
          display="flex"
          justifyContent="right">
          <PaginationTableComp
            totalDataLength={filteredPushHistoryData?.length || 0}
            pageSize={10}
            pageSizeList={[5, 10, 20, 50]}
            onChange={(obj) => setPageIndex({ startIndex: obj.startIndex, endIndex: obj.endIndex })}
          />
        </Box>
      </Box>
    </Page>
  )
}
