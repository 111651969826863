import { useDrillDownContext } from 'src/context/DrillDownContext'
import { get, set } from 'src/utils/config/lodashUtils'
import { getBreadcrumbLabel } from '../DrillDown'
import { FiltersObjType } from '../utils/jsonConfigs/types'
import DrillDownDrawer from './DrillDownDrawer'

interface DrillDownDrawerStackProps {
  fromRoute?: { label: string; route: string }
}

export default function DrillDownDrawerStack(props: DrillDownDrawerStackProps) {
  const { fromRoute } = props
  const { stack, setStack, setFilterData } = useDrillDownContext()

  return (
    <>
      {stack.map((item, index) => {
        const tempStack = stack.slice(0, index + 1)
        return (
          <DrillDownDrawer
            key={index + item.drillDownName + item.filterObj.map((e) => e.key).join('')}
            expand={index === stack.length - 2}
            preBreadcrumbArr={[
              ...(fromRoute ? [fromRoute] : []),
              {
                label: 'Metrics',
                onClick: () => {
                  setStack([])
                }
              }
            ]}
            stack={tempStack}
            open={true}
            onClose={() => {
              setStack(stack.slice(0, index))
            }}
            setFilterData={(data, drillDownName) => {
              setFilterData(data, drillDownName, getBreadcrumbLabel(data), index + 1)
            }}
            onFilterUpdate={(filterKey, newSelected, index) => {
              if (Array.isArray(newSelected) && newSelected.length > 0) {
                setStack((prev) => {
                  const arr = [...prev]
                  const data: FiltersObjType[] = get(arr, `[${index}].filterObj`, [])
                  const keyIndex = data.findIndex((e) => e.key === filterKey)
                  if (keyIndex < 0) {
                    set(arr[index], 'filterObj', [...data, { key: filterKey, value: newSelected }])
                  } else {
                    set(arr[index], `filterObj[${keyIndex}].value`, newSelected)
                  }
                  set(arr[index], 'breadcrumbObj.label', data ? getBreadcrumbLabel(data) : '')
                  return arr
                })
              } else if (Array.isArray(newSelected) && newSelected.length === 0) {
                setStack((prev) => {
                  const arr = [...prev]

                  // ? this will remove vb_platform and vb_name from Digital Stores
                  arr.splice(index, 1)

                  // ? this will remove only vb_platform or vb_name from Digital Stores
                  // const data: FiltersObjType[] = get(arr, `[${index}].filterObj`, [])
                  // if (data.length <= 1) {
                  //   arr.splice(index, 1)
                  // } else {
                  //   const filterIndex = data.findIndex((e) => e.key === filterKey)
                  //   data.splice(filterIndex, 1)

                  //   set(arr[index], 'filterObj', data)
                  //   set(arr[index], 'breadcrumbObj.label', data ? getBreadcrumbLabel(data) : '')
                  // }
                  return arr
                })
              }
            }}
          />
        )
      })}
    </>
  )
}
