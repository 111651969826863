import posthog from 'posthog-js'
import { useAuth } from 'src/context/AuthContext'
import { get } from 'src/utils/config/lodashUtils'

export default function usePostHogHook() {
  const { currentUser } = useAuth()

  const trackPostHogDownload = (body: { fileName: string; type: 'CSV' | 'PDF' | 'IIF'; path?: string }) => {
    const { fileName, type, path = window.location.href } = body
    posthog.capture('File downloaded', {
      type: type,
      name: fileName,
      path: path,
      user: {
        email: get(currentUser, 'email', ''),
        company: get(currentUser, 'org', '')
      }
    })
  }

  const trackPostHogEvent = (eventName: string, properties?: Object) => {
    posthog.capture(eventName, {
      user: {
        name: get(currentUser, 'name', ''),
        email: get(currentUser, 'email', ''),
        company: get(currentUser, 'org', '')
      },
      ...properties
    })
  }

  return {
    trackPostHogDownload,
    trackPostHogEvent
  }
}
