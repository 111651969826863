import { OrderByType } from '@LoopKitchen/loop-ui/CustomMaterialTable'
import Typo from '@LoopKitchen/loop-ui/Typo'
import {Box, Tooltip, Typography} from '@mui/material'
import moment from 'moment'
import CustomMaterialTableWrapper from 'src/components/CustomMaterialTableWrapper/CustomMaterialTableWrapper'
import PlatformLogo from 'src/components/PlatformLogo'
import { CampaignAttributedOrdersPaginated } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import {Insights, SvgIconComponent} from "@mui/icons-material";
import React from "react";
import {Stack} from "@mui/system";

interface AttributedOrdersTableProps {
  isLoading: boolean
  tableData: CampaignAttributedOrdersPaginated
  onExportCSV: (fileName: string) => void
  onExportModifyCSV: () => Promise<string>
  onLastPage: () => void
  onOrderByChange?: (type: OrderByType) => void
  onSortByChange?: (selectedOption?: { label: string; value: string }) => void
}

interface IconTooltipProps {
  tooltip: string;
  position: 'left' | 'right' | 'top' | 'bottom';
  icon: SvgIconComponent
}

const IconTooltip = ({ tooltip, position, icon: Icon }) => {
  return (
    <Tooltip
      title={tooltip}
      arrow
      placement={position}
      componentsProps={{
        tooltip: {
          style: {
            color: 'white',
            fontSize: 12,
            fontWeight: 400,
            maxWidth: 300,
            padding: '10px',
            backgroundColor: 'rgba(0, 0, 0, 1)'
          }
        },
        arrow: {
          style: {
            color: 'black'
          }
        }
      }}>
      <Box
        sx={{
          fontSize: '18px',
          color: 'black',
          borderRadius: '2px',
          bgcolor: '#E8E8E8',
          width: '25px',
          height: '25px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Icon
          fontSize="inherit"
          color="inherit"
        />
      </Box>
    </Tooltip>
  );
};


export default function AttributedOrdersTable(props: AttributedOrdersTableProps) {
  const { tableData } = props

  return (
    <Box>
      <CustomMaterialTableWrapper
        isLoading={props.isLoading}
        columns={[
          {
            title: 'Order ID',
            field: 'order_id',
            render: (data) => (
              <Box
                display="flex"
                alignItems="center"
                gap={1}>
                <Box
                  p={'2px'}
                  bgcolor="#fff">
                  <PlatformLogo
                    shortImg
                    platformName={get(data, 'vb_platform', '')}
                    width="14px"
                    height="14px"
                    boxSx={{
                      display: 'flex',
                      alignItems: 'center',
                      flex: 'none'
                    }}
                  />
                </Box>

                <Typography
                  fontSize="12px"
                  fontWeight={600}
                  color="#000"
                  lineHeight="initial">
                  {get(data, 'order_id', '')}
                </Typography>
              </Box>
            ),
            sortable: true
          },
          {
            title: 'Order Date',
            field: 'orderdatetime',
            sortable: true,
            render: (data) => {
              return (
                <Typo
                  fs={12}
                  fw={400}>
                  {moment(data['order_timestamp']).format('DD MMM YYYY hh:mm A')}
                </Typo>
              )
            }
          },
          {
            title: 'Subtotal',
            field: 'sales',
            render: (data) => {
              return (
                <Typo
                  fs={12}
                  fw={400}>
                  ${data.sales.toFixed(2)}
                </Typo>
              )
            }
          },
          {
            title: 'Payout',
            field: 'payout',
            render: (data) => {
              return (
                <Typo
                  fs={12}
                  fw={400}>
                  ${data.payout.toFixed(2)}
                </Typo>
              )
            }
          },
          {
            title: 'Cost',
            field: 'cost',
            render: (data) => {
              return (
                <Typo
                  fs={12}
                  fw={400}>
                  ${data.cost.toFixed(2)}
                </Typo>
              )
            }
          },
          {
            title: 'Cofund',
            field: 'cofund',
            render: (data) => {
              return (
                <Typo
                  fs={12}
                  fw={400}>
                  ${data.cofund.toFixed(2)}
                </Typo>
              )
            }
          }
        ]}
        data={get(tableData, 'data', [] as typeof tableData.data)}
        options={{
          sortable: true,
          showOrderBy: true,
          search: true,
          export: true,
          pagination: true,
          extraExportColumns: [
            {
              title: filterNames.b_name,
              field: 'b_name'
            },
            {
              title: filterNames.vb_name,
              field: 'vb_name'
            },
            {
              title: filterNames.vb_address,
              field: 'vb_address'
            },
            {
              title: filterNames.vb_platform,
              field: 'vb_platform'
            },
            {
              title: 'Store ID',
              field: 'store_id'
            },
            {
              title: 'Campaign ID',
              field: 'campaign_id'
            },
            {
              title: 'Campaign Name',
              field: 'campaignName'
            },
            {
              title: 'Start Date',
              field: 'startTime'
            },
            {
              title: 'End Date',
              field: 'endTime'
            }
          ],
          totalPaginatedDataLength: get(tableData, 'max_rows', undefined),
          headerCellStyle: {
            backgroundColor: 'rgba(0, 0, 0, 0.06)'
          }
        }}
        onOrderByChange={props.onOrderByChange}
        onSortByChange={props.onSortByChange}
        onLastPage={() => props.onLastPage()}
        onExportCSV={() => props.onExportCSV('Attributed Orders.csv')}
        onExportModifyCSV={() => props.onExportModifyCSV()}
      />
    </Box>
  )
}
