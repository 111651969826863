import { Grid } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { TooltipLabelsType } from 'src/components/Charts/SyncedChart/ChartTooltip'
import SyncedChart from 'src/components/Charts/SyncedChart/SyncedChart'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import DrillDownWrapper, { DrillDownWrapperProps } from 'src/pages/members/DrillDown/components/DrillDownWrapper'
import { useSyncedChartsContext } from 'src/pages/members/MarketingPages/Trends/context/SyncedChartsContext'
import { DefaultService, MarketingKPIDatewise, SalesDatewise, ThirdPartyFeeDatewise } from 'src/services/openApi'
import { debounce } from 'src/utils/config/lodashUtils'
import { useMarketingTrendsLocalContext } from '../Trends'

const defaultTooltipLabels: TooltipLabelsType = {
  marketing_driven_sales: {
    label: 'Marketing Driven Sales',
    shortLabel: 'Mktg Sales',
    format: 'currency',
    loading: true
  },
  marketing_spend: {
    label: 'Marketing Spend',
    shortLabel: 'Mktg Spend',
    format: 'currency',
    loading: true
  },
  roi: {
    label: 'ROI',
    format: null,
    loading: true
  },
  sales: {
    label: 'Sales',
    format: 'currency',
    loading: true
  },
  third_party_fee: {
    label: 'Third Party Fee',
    shortLabel: '3P Fee',
    format: 'currency',
    loading: true
  },
  third_party_fee_perc: {
    label: 'Third Party Fee Percentage',
    shortLabel: '3P Fee %',
    format: 'percentage',
    loading: true
  },
  cogs: {
    label: 'Cost of Goods Sold',
    shortLabel: 'COGS',
    format: 'currency',
    loading: true
  }
}

const OverviewMarketingCharts = () => {
  const { openError } = useSnackData()
  const { overview } = useSyncedChartsContext()
  const { activeIndex, setActiveIndex, visibleTooltip, setVisibleTooltip, aggregateData, setAggregateData } = overview
  const { getFilters, getFiltersV2 } = useFilter()
  const { channel, fulfillment } = useMarketingTrendsLocalContext()
  const [marketingLoading, setMarketingLoading] = useState(false)
  const [marketingGraphData, setMarketingGraphData] = useState<MarketingKPIDatewise[]>([])
  const [salesLoading, setSalesLoading] = useState(false)
  const [salesData, setSalesData] = useState<SalesDatewise[]>([])
  const [feeLoading, setFeeLoading] = useState(false)
  const [feeData, setFeeData] = useState<ThirdPartyFeeDatewise[]>([])
  const chartFiltersArr = ['v_name', 'vb_name', 'b_name', 'am_name', 'start_date', 'end_date', 'chain', 'vb_platform', 'granularity']
  const parentRef = useRef(null)
  const [parentWidth, setParentWidth] = useState(null)
  const [drillDownName, setDrillDownName] = useState<DrillDownWrapperProps['drillDownName']>(null)

  const filterObj = useMemo(() => {
    const obj = getFiltersV2(chartFiltersArr, true)
    return {
      channel_in: channel?.join('|'),
      fulfillment_in: fulfillment?.join('|'),
      ...obj
    }
  }, [channel, fulfillment, ...getFilters(chartFiltersArr)])

  const tooltipLabels = useMemo(
    () => ({
      ...defaultTooltipLabels,
      marketing_driven_sales: { ...defaultTooltipLabels.marketing_driven_sales, loading: marketingLoading },
      marketing_spend: { ...defaultTooltipLabels.marketing_spend, loading: marketingLoading },
      roi: { ...defaultTooltipLabels.roi, loading: marketingLoading },
      sales: { ...defaultTooltipLabels.sales, loading: salesLoading },
      third_party_fee: { ...defaultTooltipLabels.third_party_fee, loading: feeLoading },
      third_party_fee_perc: { ...defaultTooltipLabels.third_party_fee_perc, loading: feeLoading },
      cogs: { ...defaultTooltipLabels.cogs, loading: feeLoading }
    }),
    [marketingLoading, salesLoading, feeLoading]
  )

  useEffect(() => {
    if (!marketingGraphData || !salesData || !feeData) return

    const salesMap = salesData.reduce((acc, curr) => {
      acc[curr.date] = curr
      return acc
    }, {})

    const feeMap = feeData.reduce((acc, curr) => {
      acc[curr.date] = curr
      return acc
    }, {})

    const joinedData = marketingGraphData.map((item) => {
      return {
        ...item,
        sales: salesMap[item.date]?.sales,
        third_party_fee: feeMap[item.date]?.third_party_fee,
        third_party_fee_perc: feeMap[item.date]?.third_party_fee_perc,
        cogs: feeMap[item.date]?.cogs
      }
    })
    setAggregateData(joinedData)
  }, [marketingGraphData, salesData, feeData])

  async function getMarketingData() {
    setMarketingLoading(true)
    try {
      const result = await DefaultService.callMarketingKpiSummaryApiMarketingKpiSummaryPost(filterObj)
      setMarketingGraphData(result.marketing_k_p_i_datewise.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()))
    } catch (error) {
      openError(error.message)
    }
    setMarketingLoading(false)
  }
  async function getSalesData() {
    setSalesLoading(true)
    try {
      const result = await DefaultService.callSalesSummaryApiSalesSummaryPost(filterObj)
      setSalesData(result.sales_datewise.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()))
    } catch (error) {
      openError(error.message)
    }
    setSalesLoading(false)
  }
  async function getFeeData() {
    setFeeLoading(true)
    try {
      const result = await DefaultService.callThirdPartyFeeSummaryApiThirdPartyFeeSummaryPost(filterObj)
      setFeeData(result.third_party_fee_datewise.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()))
    } catch (error) {
      openError(error.message)
    }
    setFeeLoading(false)
  }

  useEffect(() => {
    Promise.all([getMarketingData(), getSalesData(), getFeeData()])
  }, [filterObj])

  useEffect(() => {
    const debouncedSetBoxWidth = debounce((width) => {
      setParentWidth(width)
    }, 500)

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        debouncedSetBoxWidth(entry.contentRect.width)
      }
    })

    if (parentRef.current) {
      resizeObserver.observe(parentRef.current)
    }

    return () => {
      if (parentRef.current) {
        resizeObserver.unobserve(parentRef.current)
      }
      debouncedSetBoxWidth.cancel()
    }
  }, [])

  return (
    <>
      <DrillDownWrapper
        drillDownName={drillDownName}
        onClose={() => {
          setDrillDownName(null)
        }}
      />
      <Grid
        container
        spacing={2}>
        <Grid
          ref={parentRef}
          item
          lg={6}
          xs={12}>
          <SyncedChart
            syncId="overview"
            title="Marketing Spend"
            loading={marketingLoading}
            data={marketingGraphData || []}
            dataKey="marketing_spend"
            yAxisType="currency"
            parentWidth={parentWidth}
            showLegend={false}
            tooltipLabels={tooltipLabels}
            onTitleClick={() => {
              setDrillDownName('MarketingSpend')
            }}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            visibleTooltip={visibleTooltip}
            setVisibleTooltip={setVisibleTooltip}
            aggregateData={aggregateData}
          />
        </Grid>

        <Grid
          item
          lg={6}
          xs={12}>
          <SyncedChart
            syncId="overview"
            title="Marketing Driven Sales"
            loading={marketingLoading}
            data={marketingGraphData || []}
            dataKey="marketing_driven_sales"
            yAxisType="currency"
            parentWidth={parentWidth}
            showLegend={false}
            tooltipLabels={tooltipLabels}
            onTitleClick={() => {
              setDrillDownName('SalesFromMarketing')
            }}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            visibleTooltip={visibleTooltip}
            setVisibleTooltip={setVisibleTooltip}
            aggregateData={aggregateData}
          />
        </Grid>

        <Grid
          item
          lg={6}
          xs={12}>
          <SyncedChart
            syncId="overview"
            title="ROI"
            loading={marketingLoading}
            data={marketingGraphData || []}
            dataKey="roi"
            parentWidth={parentWidth}
            tooltipLabels={tooltipLabels}
            showLegend={false}
            onTitleClick={() => {
              setDrillDownName('Roi')
            }}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            visibleTooltip={visibleTooltip}
            setVisibleTooltip={setVisibleTooltip}
            aggregateData={aggregateData}
          />
        </Grid>

        <Grid
          item
          lg={6}
          xs={12}>
          <SyncedChart
            syncId="overview"
            title="Sales"
            loading={salesLoading}
            data={salesData || []}
            dataKey="sales"
            yAxisType="currency"
            parentWidth={parentWidth}
            showLegend={false}
            tooltipLabels={tooltipLabels}
            onTitleClick={() => {
              setDrillDownName('Sales')
            }}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            visibleTooltip={visibleTooltip}
            setVisibleTooltip={setVisibleTooltip}
            aggregateData={aggregateData}
          />
        </Grid>
        {feeData.filter((x) => {
          x.cogs > 0
        }).length > 0 && (
          <Grid
            item
            lg={6}
            xs={12}>
            <SyncedChart
              syncId="overview"
              title="Cost of Goods Sold"
              loading={feeLoading}
              data={feeData || []}
              dataKey="cogs"
              yAxisType="currency"
              parentWidth={parentWidth}
              tooltipLabels={tooltipLabels}
              showLegend={false}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              visibleTooltip={visibleTooltip}
              setVisibleTooltip={setVisibleTooltip}
              aggregateData={aggregateData}
            />
          </Grid>
        )}

        {feeData.filter((x) => {
          x.cogs_perc > 0
        }).length > 0 && (
          <Grid
            item
            lg={6}
            xs={12}>
            <SyncedChart
              syncId="overview"
              title="Cost of Goods Sold (in percentage)"
              loading={feeLoading}
              data={feeData || []}
              dataKey="cogs_perc"
              yAxisType="percentage"
              parentWidth={parentWidth}
              tooltipLabels={tooltipLabels}
              showLegend={false}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              visibleTooltip={visibleTooltip}
              setVisibleTooltip={setVisibleTooltip}
              aggregateData={aggregateData}
            />
          </Grid>
        )}

        <Grid
          item
          lg={6}
          xs={12}>
          <SyncedChart
            syncId="overview"
            title="Third Party Fee"
            loading={feeLoading}
            data={feeData || []}
            dataKey="third_party_fee"
            yAxisType="currency"
            parentWidth={parentWidth}
            tooltipLabels={tooltipLabels}
            showLegend={false}
            onTitleClick={() => {
              setDrillDownName('ThirdPartyFee')
            }}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            visibleTooltip={visibleTooltip}
            setVisibleTooltip={setVisibleTooltip}
            aggregateData={aggregateData}
          />
        </Grid>

        <Grid
          item
          lg={6}
          xs={12}>
          <SyncedChart
            syncId="overview"
            title="Third Party Fee (in percentage)"
            loading={feeLoading}
            data={feeData || []}
            dataKey="third_party_fee_perc"
            yAxisType="percentage"
            parentWidth={parentWidth}
            tooltipLabels={tooltipLabels}
            showLegend={false}
            onTitleClick={() => {
              setDrillDownName('ThirdPartyFeePerc')
            }}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            visibleTooltip={visibleTooltip}
            setVisibleTooltip={setVisibleTooltip}
            aggregateData={aggregateData}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default OverviewMarketingCharts
