import { SvgIcon, SvgIconProps } from '@mui/material'

const PushHistoryIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="17"
        viewBox="0 0 24 17"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.00977 16.4473C4.27643 15.9973 2.8431 15.0639 1.70977 13.6473C0.576432 12.2306 0.00976562 10.5806 0.00976562 8.69727C0.00976562 6.81393 0.576432 5.16393 1.70977 3.74727C2.8431 2.3306 4.27643 1.39727 6.00977 0.947266V3.04727C4.82643 3.44727 3.86393 4.16393 3.12227 5.19727C2.3806 6.2306 2.00977 7.39727 2.00977 8.69727C2.00977 9.99727 2.3806 11.1639 3.12227 12.1973C3.86393 13.2306 4.82643 13.9473 6.00977 14.3473V16.4473ZM14.0098 16.6973C11.7931 16.6973 9.9056 15.9181 8.34727 14.3598C6.78893 12.8014 6.00977 10.9139 6.00977 8.69727C6.00977 6.4806 6.78893 4.5931 8.34727 3.03477C9.9056 1.47643 11.7931 0.697266 14.0098 0.697266C15.1098 0.697266 16.1431 0.905599 17.1098 1.32227C18.0764 1.73893 18.9264 2.31393 19.6598 3.04727L18.2598 4.44727C17.7098 3.89727 17.0723 3.4681 16.3473 3.15977C15.6223 2.85143 14.8431 2.69727 14.0098 2.69727C12.3431 2.69727 10.9264 3.2806 9.75977 4.44727C8.5931 5.61393 8.00977 7.0306 8.00977 8.69727C8.00977 10.3639 8.5931 11.7806 9.75977 12.9473C10.9264 14.1139 12.3431 14.6973 14.0098 14.6973C14.8431 14.6973 15.6223 14.5431 16.3473 14.2348C17.0723 13.9264 17.7098 13.4973 18.2598 12.9473L19.6598 14.3473C18.9264 15.0806 18.0764 15.6556 17.1098 16.0723C16.1431 16.4889 15.1098 16.6973 14.0098 16.6973ZM20.0098 12.6973L18.6098 11.2973L20.2098 9.69727H13.0098V7.69727H20.2098L18.6098 6.09727L20.0098 4.69727L24.0098 8.69727L20.0098 12.6973Z"
          fill="#1C1B1F"
        />
      </svg>
    </SvgIcon>
  )
}

export default PushHistoryIcon
