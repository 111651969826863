import Drawer from '@LoopKitchen/loop-ui/Drawer'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import BreadcrumbsComp from 'src/components/BreadcrumbsComp'
import { DrillDownStackObjType, useDrillDownContext } from 'src/context/DrillDownContext'
import allJsonData from 'src/pages/members/DrillDown/utils/jsonConfigs'
import componentsObject from 'src/pages/members/DrillDown/utils/jsonConfigs/componentsObject'
import { BreakdownEnumsType, FiltersObjType } from 'src/pages/members/DrillDown/utils/jsonConfigs/types'
import { BreadcrumbsQueueType } from 'src/types/BreadcrumbsQueueType'
import { get } from 'src/utils/config/lodashUtils'
import DrillDownFilterComponent from './DrillDownFilters'
import DrillDownTable from './DrillDownTable'
import MetricsByStoreTable from './MetricsByStoreTable'

type DrillDownDrawerProps = {
  open: boolean
  expand?: boolean
  preBreadcrumbArr?: BreadcrumbsQueueType[]
  onClose: () => void
  setFilterData: (data: FiltersObjType[], drillDownName: keyof typeof allJsonData) => void
  onFilterUpdate: (filterKey: BreakdownEnumsType, newSelected: string[], index: number) => void
} & (
  | {
      stack: DrillDownStackObjType[]
    }
  | { drillDownName: keyof typeof allJsonData }
)

export default function DrillDownDrawer(props: DrillDownDrawerProps) {
  const { open: propsOpen, preBreadcrumbArr, expand, onClose, setFilterData, onFilterUpdate } = props
  const [open, setOpen] = React.useState(false)
  const { getFiltersArr } = useDrillDownContext()

  const propsDrillDownName = 'drillDownName' in props ? props.drillDownName : null

  const stack = React.useMemo(() => {
    if ('stack' in props) {
      return props.stack
    }
    return [] as DrillDownStackObjType[]
  }, ['stack' in props ? props.stack : null])

  const { drillDownName, filterObj } = React.useMemo(() => {
    if (propsDrillDownName) {
      return { drillDownName: propsDrillDownName, filterObj: null }
    }
    if (stack.length === 0) {
      return {} as (typeof stack)[0]
    }
    return stack.at(-1)
  }, [stack, propsDrillDownName])

  const filtersArr = React.useMemo(() => {
    return getFiltersArr(stack)
  }, [stack])

  const getValueIfSingle = React.useCallback(
    (key: string) => {
      const obj = filtersArr.find((e) => e.key === key)
      if (obj && get(obj, 'value.length', 0) === 1) {
        return obj.value[0]
      }
      return null
    },
    [filtersArr]
  )

  const bName = React.useMemo(() => {
    return getValueIfSingle('b_name')
  }, [filtersArr])

  const vbName = React.useMemo(() => {
    return getValueIfSingle('vb_name')
  }, [filtersArr])

  const vbPlatform = React.useMemo(() => {
    return getValueIfSingle('vb_platform')
  }, [filtersArr])

  const showOnlyTitle = React.useMemo(() => {
    const result = filtersArr.some((e) => e.value.length > 1)
    return result
  }, [filtersArr])

  const canShowFinalComp = React.useMemo(() => {
    return filtersArr.length >= 3
  }, [filtersArr])

  const jsonData = React.useMemo(() => {
    if (drillDownName in allJsonData) {
      return allJsonData[drillDownName]
    }
    return null
  }, [drillDownName, allJsonData])

  const finalCompObj = React.useMemo(() => {
    if (jsonData && jsonData['finalComponentKey'] && jsonData['finalComponentKey'] in componentsObject) {
      return componentsObject[jsonData['finalComponentKey']]
    }
    return null
  }, [jsonData])

  const { Component, props: finalCompProps } = React.useMemo(() => {
    if (finalCompObj) {
      return finalCompObj
    }
    return {} as typeof finalCompObj
  }, [finalCompObj])

  const handleClose = () => {
    setOpen(false)
    setTimeout(() => {
      onClose()
    }, 0)
  }

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOpen(propsOpen)
    }, 0)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [propsOpen])

  if (!drillDownName || !jsonData) {
    return <></>
  }

  return (
    <Drawer
      open={open}
      onClose={handleClose}>
      <Stack
        direction="row"
        alignItems="flex-start"
        style={{ width: `calc(100vw - ${expand ? 100 : 300}px)`, transition: 'width 0.5s', overflow: 'clip' }}>
        <IconButton
          size="small"
          onClick={() => {
            onClose()
            // setSearchParamsFromFilterArr(filtersArr.slice(0, -1))
          }}>
          <KeyboardBackspaceIcon sx={{ fontSize: '14px', color: 'black' }} />
        </IconButton>
        <Box sx={{ pl: '12px', flex: 1, minWidth: '0px' }}>
          <Tooltip
            arrow
            title={get(filterObj, 'value', []).join(', ')}
            placement="bottom">
            <Typography
              fontSize={'20px'}
              lineHeight={'20px'}
              sx={{ mb: '15px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {get(jsonData, 'title', drillDownName)}{' '}
              {vbName && !showOnlyTitle && (
                <>
                  for <span style={{ fontWeight: 600 }}>{vbName}</span>
                </>
              )}{' '}
              {vbPlatform && !showOnlyTitle && (
                <>
                  of <span style={{ fontWeight: 600 }}>{vbPlatform}</span>
                </>
              )}{' '}
              {bName && !showOnlyTitle && (
                <>
                  {vbName || vbPlatform ? 'at' : 'for'} <span style={{ fontWeight: 600 }}>{bName}</span>
                </>
              )}
            </Typography>
          </Tooltip>
          <Box sx={{ mb: '18px' }}>
            <BreadcrumbsComp
              breadcrumbArr={[
                ...(preBreadcrumbArr || []),
                ...stack.map((e, i) => (i < stack.length - 1 ? e.breadcrumbObj : { label: get(e, 'breadcrumbObj.label', '') }))
              ]}
            />
          </Box>
          <Box>
            <DrillDownFilterComponent
              stack={stack}
              onFilterUpdate={onFilterUpdate}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            {canShowFinalComp && finalCompObj ? (
              <Component {...finalCompProps} />
            ) : drillDownName === 'MetricsKPI' ? (
              <MetricsByStoreTable
                stickyHeader="-16px"
                lockUI={false}
                filtersArr={filtersArr}
                setFilterData={(data, newDrillDownName) => {
                  setFilterData(data, newDrillDownName)
                }}
              />
            ) : (
              <DrillDownTable
                drillDownName={drillDownName}
                jsonData={jsonData}
                filtersArr={filtersArr}
                setFilterData={(data) => {
                  setFilterData(data, drillDownName)
                }}
              />
            )}
          </Box>
        </Box>
      </Stack>

      {/* TODO filters component */}
    </Drawer>
  )
}
