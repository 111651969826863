import { CampaignDetailPaginatedV3 } from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService'
import {Box, Stack, Tooltip, Typography} from '@mui/material'
import React from 'react'
import BorderedPriceBox from 'src/components/OrderDrawer/components/helpers/BorderedPriceBox'
import HeadingContainer from 'src/components/OrderDrawer/components/helpers/HeadingContainer'
import { formatCurrency } from 'src/utils/functions'
import {HelpCenter, HelpOutlineOutlined, QuestionMark, SvgIconComponent} from "@mui/icons-material";

interface BreakdownCompProps {
  charges: CampaignDetailPaginatedV3
}

interface BreakdownItem {
  label: string
  price: number
  color: string
  tooltip?: string
  isBreakdown?: boolean
}

interface IconTooltipProps {
  tooltip: string;
  position: 'left' | 'right' | 'top' | 'bottom';
  icon: SvgIconComponent
}

const IconTooltip = ({ tooltip, position, icon: Icon }) => {
  return (
    <Tooltip
      title={tooltip}
      arrow
      placement={position}
      componentsProps={{
        tooltip: {
          style: {
            color: 'white',
            fontSize: 12,
            fontWeight: 400,
            maxWidth: 300,
            padding: '10px',
            backgroundColor: 'rgba(0, 0, 0, 1)'
          }
        },
        arrow: {
          style: {
            color: 'black'
          }
        }
      }}>
      <Box
        sx={{
          fontSize: '16px',
          color: 'black',
          borderRadius: '2px',
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Icon
          fontSize="inherit"
          color="inherit"
        />
      </Box>
    </Tooltip>
  );
};



export default function CampaignSalesBreakdown(props: BreakdownCompProps) {
  const { charges } = props

  const attr_data_sales = React.useMemo(() => {
    const arr: BreakdownItem[] = []
    arr.push({
      label: `Sales (${charges.attr_orders} orders)`,
      price: charges?.attr_sales,
      color: '#1E1E1E'
    })
    arr.push({
      label: `Additional Costs`,
      price: Math.abs(charges.attr_sales - charges.attr_payout - charges.attr_spend + charges.attr_cofund),
      color: '#FF0000',
      tooltip: 'All Third party costs excluding marketing spend'
    })
    arr.push({
      label: `Effective Sales`,
      price: Math.abs(charges.attr_payout + charges.attr_spend - charges.attr_cofund),
      color: '#1E1E1E',
      isBreakdown: true
    })
    return arr
  },[charges])
  
  const attr_data_spend = React.useMemo(() => {
    const arr: BreakdownItem[] = []
    arr.push({
      label: `Marketing Spend`,
      price: Math.abs(charges.attr_spend),
      color: '#FF0000'
    })
    arr.push({
      label: `Cofunding (${charges.attr_cofund_orders} orders)`,
      price: Math.abs(charges.attr_cofund),
      color: '#1E1E1E'
    })
    arr.push({
      label: `Effective Marketing Spend`,
      price: Math.abs(charges.attr_spend - charges.attr_cofund),
      color: '#FF0000',
      isBreakdown: true
    })
    return arr
  }, [charges])
  
  const attr_payout = React.useMemo(() => {
    const arr: BreakdownItem[] = []
    arr.push({
      label: `Net Payout`,
      price: Math.abs(charges.attr_payout),
      color: '#1E1E1E',
      isBreakdown: true
    })
    return arr
  }, [charges])

  const unattr_data = React.useMemo(() => {
    const arr: BreakdownItem[] = []
    arr.push({
      label: `Sales` + (charges.orders ? ` (${charges.orders - charges.attr_orders} orders)` : ''),
      price: Math.max(charges.sales - charges.attr_sales, 0),
      color: '#1E1E1E'
    })

    charges.cost &&
      arr.push({
        label: `Marketing Spend`,
        price: Math.max(charges.cost - charges.attr_spend, 0),
        color: '#FF0000'
      })
    return arr
  }, [charges])

  const reported_data = React.useMemo(() => {
    const arr: BreakdownItem[] = []

    arr.push({
      label: `Sales` + (charges.orders ? ` (${charges.orders} orders)` : ''),
      price: charges.sales,
      color: '#1E1E1E'
    })

    charges.cost &&
      arr.push({
        label: `Marketing Spend`,
        price: Math.abs(charges.cost),
        color: '#FF0000'
      })

    return arr
  }, [charges])

  return (
    <>
    <HeadingContainer
      icon={'storefront'}
      title="Total"
    >
      <Stack
        direction="column"
        alignItems="stretch"
        gap="5px"
        sx={{ pl: '15px', mb: '15px' }}>
        {reported_data.map((item) => {
          return (
            <Stack direction="row" alignItems="center" gap="4px" sx={{
              border: '1px solid #EEEEEE',
              borderRadius: '2px',
              backgroundColor: 'rgba(253, 253, 253, 1)',
              width: '100%',
              height: '100%',
              p: '4px'
            }}>
              <Typography fontSize={13} >{item.label}</Typography>
              {item.tooltip &&  (<IconTooltip tooltip={item.tooltip} position={'top'} icon={QuestionMark} />)}
              <Box sx={{ flexGrow: 1 }} /> {/* This pushes the tooltip to the right */}
              <Typography fontSize={13} color={item.color}>{formatCurrency(item.price, { maxFractionDigits: 2 })}</Typography>
            </Stack>
          )
        })}
      </Stack>
    </HeadingContainer>
    <HeadingContainer
      icon={'link_off'}
      title="Unattributed">
      <Stack
        direction="column"
        alignItems="stretch"
        gap="5px"
        sx={{ pl: '15px' }}>
        {unattr_data.map((item) => {
          return (
            <Stack direction="row" alignItems="center" gap="4px" sx={{
              border: '1px solid #EEEEEE',
              borderRadius: '2px',
              backgroundColor: 'rgba(253, 253, 253, 1)',
              width: '100%',
              height: '100%',
              p: '4px'
            }}>
              <Typography fontSize={item.isBreakdown ? 15 : 13} fontWeight={item.isBreakdown ? 600 : 400} >{item.label}</Typography>
              {item.tooltip &&  (<IconTooltip tooltip={item.tooltip} position={'top'} icon={QuestionMark} />)}
              <Box sx={{ flexGrow: 1 }} />
              <Typography fontSize={item.isBreakdown ? 15 : 13} fontWeight={item.isBreakdown ? 600 : 400} color={item.color}>{formatCurrency(item.price, { maxFractionDigits: 2 })}</Typography>
            </Stack>
          )
        })}
      </Stack>
    </HeadingContainer>
    <HeadingContainer
      sx = {{marginTop: '5px'}}
      icon={'insights'}
      title="Attributed">
      <Stack gap={2}>
        <Stack
          direction="column"
          alignItems="stretch"
          gap="5px"
          sx={{ pl: '15px' }}>
          {attr_data_sales.map((item) => {
            return (
              <Stack direction="row" alignItems="center" gap="4px" sx={{
                border: '1px solid #EEEEEE',
                borderRadius: '2px',
                backgroundColor: 'rgba(253, 253, 253, 1)',
                width: '100%',
                height: '100%',
                p: '4px'
              }}>
                <Typography fontSize={13} fontWeight={item.isBreakdown ? 600 : 400} >{item.label}</Typography>
                {item.tooltip &&  (<IconTooltip tooltip={item.tooltip} position={'top'} icon={HelpOutlineOutlined} />)}
                <Box sx={{ flexGrow: 1 }} /> {/* This pushes the tooltip to the right */}
                <Typography fontSize={13} fontWeight={item.isBreakdown ? 600 : 400} color={item.color}>{formatCurrency(item.price, { maxFractionDigits: 2 })}</Typography>
              </Stack>
            )
          })}
        </Stack>
        <Stack
          direction="column"
          alignItems="stretch"
          gap="5px"
          marginTop={1}
          sx={{ pl: '15px' }}>
          {attr_data_spend.map((item) => {
            return (
              <Stack direction="row" alignItems="center" gap="4px" sx={{
                border: '1px solid #EEEEEE',
                borderRadius: '2px',
                backgroundColor: 'rgba(253, 253, 253, 1)',
                width: '100%',
                height: '100%',
                p: '4px'
              }}>
                <Typography fontSize={13} fontWeight={item.isBreakdown ? 600 : 400}>{item.label}</Typography>
                <Box sx={{ flexGrow: 1 }} /> {/* This pushes the tooltip to the right */}
                <Typography fontSize={13} fontWeight={item.isBreakdown ? 600 : 400} color={item.color}>{formatCurrency(item.price, { maxFractionDigits: 2 })}</Typography>
              </Stack>
            )
          })}
        </Stack>
        <Stack
          direction="column"
          alignItems="stretch"
          gap="5px"
          marginTop={1}
          sx={{ pl: '15px' }}>
          {attr_payout.map((item) => {
            return (
              <Stack direction="row" alignItems="center" gap="4px" sx={{
                border: '1px solid #EEEEEE',
                borderRadius: '2px',
                backgroundColor: 'rgba(253, 253, 253, 1)',
                width: '100%',
                height: '100%',
                p: '4px'
              }}>
                <Typography fontSize={16} fontWeight={item.isBreakdown ? 600 : 400}>{item.label}</Typography>
                <Box sx={{ flexGrow: 1 }} /> {/* This pushes the tooltip to the right */}
                <Typography fontSize={16} fontWeight={item.isBreakdown ? 600 : 400} color={item.color}>{formatCurrency(item.price, { maxFractionDigits: 2 })}</Typography>
              </Stack>
            )
          })}
        </Stack>
      </Stack>
    
    </HeadingContainer>
    </>
  )
}
