import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import intersection from 'lodash/intersection'

import isEqual from 'lodash/isEqual'
import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'

import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import max from 'lodash/max'
import merge from 'lodash/merge'
import orderBy from 'lodash/orderBy'
import round from 'lodash/round'
import set from 'lodash/set'
import some from 'lodash/some'
import sortBy from 'lodash/sortBy'
import unionBy from 'lodash/unionBy'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import uniqueId from 'lodash/uniqueId'
import uniqWith from 'lodash/uniqWith'
import lodashValues from 'lodash/values'
// Examples of functions you could replace with native JS:

// Instead of lodash/capitalize
const capitalize = (str: string | null | undefined): string => {
  if (typeof str !== 'string' || !str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// Instead of lodash/isNull
const isNull = (value: unknown): value is null => value === null

// Instead of lodash/isEmpty
const isEmpty = (value: unknown): boolean => {
  if (value === null || value === undefined) return true
  if (typeof value === 'number' || typeof value === 'boolean') return false
  if (Array.isArray(value) || typeof value === 'string') return value.length === 0
  if (value instanceof Map || value instanceof Set) return value.size === 0
  if (value instanceof Object && !Object.getPrototypeOf(value)) {
    return Object.keys(value).length === 0
  }
  return false
}

export {
  capitalize,
  cloneDeep,
  concat,
  debounce,
  find,
  forEach,
  get,
  intersection,
  isEmpty,
  isEqual,
  isNaN,
  isNil,
  isNull,
  keyBy,
  lodashValues,
  map,
  max,
  merge,
  orderBy,
  round,
  set,
  some,
  sortBy,
  unionBy,
  uniq,
  uniqBy,
  uniqWith,
  uniqueId
}
