 
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BASE_API_URL } from '../config/config'

/**
 * DataForDateRange
 * This class is used to store data for a date range.
 */
export interface DataForDateRange {
  /** Start Date */
  start_date: string
  /** End Date */
  end_date: string
  /** Data For Period */
  data_for_period: object[]
}

/**
 * DataResponse
 * This class is used to store data for two date ranges.
 */
export interface DataResponse {
  /** This class is used to store data for a date range. */
  prev_period: DataForDateRange
  /** This class is used to store data for a date range. */
  this_period: DataForDateRange
}

/**
 * FiltersForQuery
 * This class is used to store filters for a query.
 */
export interface FiltersForQuery {
  /** Am Name In */
  am_name_in?: string
  /** Order Type */
  order_type?: string
  /** Reasons In */
  reasons_in?: string
  /** B Name In */
  b_name_in?: string
  /** B Name IDs In */
  b_name_ids_in?: string
  /** MarketPlace In */
  platform_in?: string
  vb_platform_in?: string
  /** Inaccurate Items In */
  inaccurate_Items_in?: string
  /** Issue Name */
  issue_name?: string
  /** Order In */
  order_in?: string
  /** End Date In */
  end_date_in?: string
  /** Error Category In */
  error_category_in?: string
  /** Vb Name In */
  vb_name_in?: string
  /** Reason In */
  reason_in?: string
  /** By Column In */
  by_column_in?: string
  /** Error Subcategory In */
  error_subcategory_in?: string
  /** Feedback Not Null */
  feedback_not_null?: string
  /** X Level In */
  x_level_in?: string
  /** Order Id In */
  order_id_in?: string
  /** Chain In */
  chain_in: string
  /** Start Date In */
  start_date_in?: string
  /** Issues In */
  issues_in?: string
  granularity_in?: string
  should_adjust_dates?: boolean
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: any[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = ''
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys.map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key))).join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) => (input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input),
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(key, property instanceof Blob ? property : typeof property === 'object' && property !== null ? JSON.stringify(property) : `${property}`)
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body)
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title Data Proxy Service
 * @version 0.1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  run = {
    /**
     * @description Call procedure for this date range and previous date range and return both. Assumes last two paramaters are start_date and end_date.
     *
     * @name RunProcedureAndGetDataWithPreviousDateRangeRunQueryDateRangeComparePost
     * @summary Run Procedure And Get Data With Previous Date Range
     * @request POST:/run/query/date_range_compare
     */
    runProcedureAndGetDataWithPreviousDateRangeRunQueryDateRangeComparePost: (
      query: {
        /** Procedure Name */
        procedure_name: string
      },
      data: string[],
      params: RequestParams = {}
    ) =>
      this.request<DataResponse, HTTPValidationError>({
        path: `${BASE_API_URL}/run/query/date_range_compare`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name RunProcedureAndGetDataRunQueryPost
     * @summary Run Procedure And Get Data
     * @request POST:/run/query
     */
    runProcedureAndGetDataRunQueryPost: (
      query: {
        /** Procedure Name */
        procedure_name: string
      },
      data: string[],
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `${BASE_API_URL}/run/query`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name RunProcedureAndGetDataRunQueryV2Post
     * @summary Run Procedure And Get Data
     * @request POST:/run/query/v2
     */
    runProcedureAndGetDataRunQueryV2Post: (
      query: {
        /** Procedure Name */
        procedure_name: string
      },
      data: FiltersForQuery,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `${BASE_API_URL}/run/query/v2`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name RunProcedureAndGetDataWithPaginationRunQueryPaginatedPost
     * @summary Run Procedure And Get Data With Pagination
     * @request POST:/run/query/paginated
     */
    runProcedureAndGetDataWithPaginationRunQueryPaginatedPost: (
      query: {
        /** Procedure Name */
        procedure_name: string
        /**
         * Offset
         * @default 0
         */
        offset?: number
        /**
         * Limit
         * @default 500
         */
        limit?: number
      },
      data: string[],
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `${BASE_API_URL}/run/query/paginated`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @name RunProcedureAndGetDataNamedRunQueryNamedPost
     * @summary Run Procedure And Get Data Named
     * @request POST:/run/query_named
     */
    runProcedureAndGetDataNamedRunQueryNamedPost: (
      query: {
        /** Procedure Name */
        procedure_name: string
      },
      data: object,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `${BASE_API_URL}/run/query_named`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  query = {
    /**
     * No description
     *
     * @name GetQueryParamsQueryParamsGet
     * @summary Get Query Params
     * @request GET:/query/params
     */
    getQueryParamsQueryParamsGet: (
      query: {
        /** Procedure Name */
        procedure_name: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/query/params`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      })
  }
  cache = {
    /**
     * No description
     *
     * @name FlushKeysCacheFlushKeysGet
     * @summary Flush Keys
     * @request GET:/cache/flush_keys
     */
    flushKeysCacheFlushKeysGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/cache/flush_keys`,
        method: 'GET',
        format: 'json',
        ...params
      })
  }
}
