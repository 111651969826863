import { yupResolver } from '@hookform/resolvers/yup'
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import Icon from '@mui/material/Icon'
import InputBase from '@mui/material/InputBase'
import InputLabel from '@mui/material/InputLabel'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import OTPInput from 'react-otp-input'
import flagImg from 'src/assets/flag.png'
import { useAuth } from 'src/context/AuthContext'
import { useErrorData } from 'src/context/ErrorContext'
import { useSnackData } from 'src/context/SnackContext'
import { auth } from 'src/services/firebase'
import { DefaultService } from 'src/services/openApi'
import { get } from 'src/utils/config/lodashUtils'
import { setLocal } from 'src/utils/functions/localStorage'
import useTimer from 'src/utils/hooks/useTimer'
import * as yup from 'yup'
import StyledTypo, { TypoContainer } from './StyledTypo'

interface LinkPhoneProps {
  togglePhone: () => void
  phoneNumber?: string
  showOTP?: boolean
  getLinkAccountFunc?: (func: () => void) => void
}

export interface LinkPhoneRef {
  linkAccounts: () => void
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="#000000000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      onChange={() => {}}
      overwrite
    />
  )
})

const invalidPhoneError = 'Firebase: Error (auth/invalid-phone-number).'
const duplicateAccountError = 'Firebase: Error (auth/account-exists-with-different-credential)'

const validationSchema = yup.object({
  phone: yup
    .string()
    .matches(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, 'Invalid phone number')
    .required('Phone number is required')
})

function LinkPhone(props: LinkPhoneProps, ref: React.ForwardedRef<LinkPhoneRef>) {
  const { togglePhone } = props
  const theme = useTheme()
  const { handleAsync, handleError } = useErrorData()
  const { currentUser, setCurrentUser } = useAuth()
  const { openError, openSuccess } = useSnackData()
  const { timer, resetTimer } = useTimer(20)
  const [internal, setInternal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showOTP, setShowOTP] = useState(props.showOTP)
  const {
    control,
    getValues,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      phone: '',
      otp: ''
    },
    resolver: yupResolver(validationSchema),
    mode: 'all'
  })

  const phoneNumber = React.useMemo(() => {
    const countryCode = '+1'
    const phone = countryCode + getValues('phone').replace(/[^\d]/g, '')
    return phone
  }, [watch('phone')])

  React.useImperativeHandle(ref, () => {
    return {
      linkAccounts: linkAccounts
    }
  })

  React.useEffect(() => {
    if (props.phoneNumber) {
      reset({ phone: props.phoneNumber, otp: '' })
    }
  }, [props.phoneNumber])

  React.useEffect(() => {
    if (props.getLinkAccountFunc) {
      props.getLinkAccountFunc(linkAccounts)
    }
  }, [props.getLinkAccountFunc])

  React.useEffect(() => {
    if (currentUser) {
      if (currentUser.email.includes('loopkitchen') || currentUser.email.includes('tryloop') || currentUser.email.includes('loopai')) {
        setInternal(true)
      } else setInternal(false)
    }
  }, [currentUser])

  async function onOTPVerify() {
    handleAsync(async () => {
      setLoading(true)
      try {
        const otp = getValues('otp')
        if (otp) {
          try {
            const res = await DefaultService.verifyOtpPhoneVerifyPhoneOtpPost({
              phone_number: phoneNumber,
              otp: otp
            })
            if (res?.success) {
              openSuccess('Phone number linked successfully')
              setCurrentUser((prev) => ({
                ...prev,
                phoneNumber: phoneNumber
              }))
              if (togglePhone) {
                togglePhone()
              }
            } else {
              handleError(res?.message || 'Something went wrong. Please try again later.')
            }
            // setCurrentUser({
            //   ...currentUser,
            //   phoneNumber
            // })
          } catch (error) {
            handleError('Something went wrong. Please try again later.')
          }
        } else {
          throw new Error('Verification code not entered.')
        }
      } catch (err) {
        handleError(err.message)
      } finally {
        setLoading(false)
      }
    })
  }
  const linkAccounts = () => {
    try {
      setLoading(true)
      const currentUser = auth.currentUser
      if (currentUser) {
        DefaultService.sendPhoneNumberOtpSendPhoneNumberOtpPost({
          phone_number: phoneNumber
        })
          .then((res) => {
            if (res?.success) {
              setShowOTP(true)
              setLoading(false)
              resetTimer()
            } else {
              openError(res?.message || 'Something went wrong. Please try again later.')
              setLoading(false)
            }
          })
          .catch((error) => {
            console.error('Error in sending OTP: ', error)
            handleError('Something went wrong. Please try again later.')
          })
      }
    } catch (error) {
      setLoading(false)
      openError(error.message)
    }
  }

  const clearRecaptcha = () => {
    try {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear()
      }
    } catch (error) {
      console.log('error in clear recaptcha: ', error)
    }
  }

  useEffect(() => {
    return () => {
      // clearRecaptcha()
    }
  }, [])

  return (
    <Paper
      elevation={3}
      sx={{
        zIndex: theme.zIndex.drawer,
        p: 4,
        borderRadius: 1,
        border: '1px solid #E1E1E1'
      }}>
      <Box display="flex">
        <TypoContainer>
          <Icon sx={{ mr: 1 }}>phone</Icon>
        </TypoContainer>
        <TypoContainer sx={{ textAlign: 'left' }}>
          <StyledTypo>Please link your email to your phone number</StyledTypo>
          {showOTP && (
            <StyledTypo
              fs={14}
              sx={{ opacity: 0.5 }}>
              Code is sent to {`${phoneNumber}`}
            </StyledTypo>
          )}
        </TypoContainer>
        <div style={{ flexGrow: 1, width: 50 }} />
        <Button
          variant="text"
          onClick={() => {
            togglePhone()
            setLocal('no-phone', true)
          }}
          sx={{ fontSize: 12, fontWeight: 400, color: '#196E82' }}>
          Skip for now
        </Button>
      </Box>
      {!showOTP ? (
        <Box
          display="flex"
          mt={2}>
          <Box>
            <Controller
              control={control}
              name="phone"
              render={({ field: { value, onChange } }) => (
                <Box sx={{ width: '100%', mb: 2 }}>
                  <StyledTypo
                    fs={14}
                    sx={{ mb: 0.6 }}>
                    Phone
                  </StyledTypo>
                  <FormControl
                    variant="outlined"
                    sx={{
                      border: `1px solid ${get(errors, 'phone') ? 'red' : 'rgba(0,0,0,0.2)'}`,
                      minWidth: 400
                    }}
                    fullWidth
                    error={Boolean(get(errors, 'phone'))}>
                    <InputBase
                      sx={{ bgcolor: 'white', p: 1.5 }}
                      value={value}
                      placeholder="Enter your phone"
                      onChange={onChange}
                      startAdornment={
                        <span
                          style={{
                            color: 'gray',
                            paddingRight: 8,
                            display: 'flex'
                          }}>
                          <TypoContainer>
                            <img
                              src={flagImg}
                              style={{
                                height: 20,
                                width: 'auto',
                                marginRight: 4
                              }}
                              alt="flag"
                            />
                          </TypoContainer>
                          {'+1'}
                        </span>
                      }
                      inputComponent={TextMaskCustom as any}
                    />
                  </FormControl>
                  {get(errors, 'phone_number.message', '') && <InputLabel sx={{ color: 'red' }}>{`${get(errors, 'phone_number.message')}`}</InputLabel>}
                </Box>
              )}
            />
            <StyledTypo
              fs={14}
              fw={400}
              sx={{ opacity: 0.5 }}>
              You’ll receive a 6 digit code to verify next
            </StyledTypo>
          </Box>
          <Box
            height="auto"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            pb={4.5}
            width="100%"
            ml={2}>
            <Button
              fullWidth
              disabled={loading || Boolean(get(errors, 'phone')) || getValues('phone') === ''}
              endIcon={
                loading && (
                  <CircularProgress
                    size={20}
                    sx={{ color: 'white' }}
                  />
                )
              }
              sx={{ maxHeight: 55, minHeight: 55 }}
              onClick={() => {
                linkAccounts()
              }}>
              Save
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <Controller
            control={control}
            name="otp"
            render={({ field: { value, onChange } }) => {
              return (
                <Box>
                  <InputLabel sx={{ mt: 1 }}>OTP</InputLabel>
                  <Box
                    sx={{
                      '& input': {
                        p: 1,
                        fontSize: 18
                      },
                      display: 'flex'
                    }}>
                    <OTPInput
                      value={value}
                      onChange={onChange}
                      numInputs={4}
                      containerStyle={{
                        width: 370,
                        justifyContent: 'space-between'
                      }}
                      inputStyle={{
                        width: 40
                      }}
                      shouldAutoFocus
                      renderSeparator={<span style={{ marginLeft: 8, marginRight: 8 }}>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                    <Button
                      sx={{ ml: 2 }}
                      fullWidth
                      onClick={onOTPVerify}
                      startIcon={<AddToHomeScreenIcon />}
                      disabled={loading}
                      endIcon={
                        loading && (
                          <CircularProgress
                            size={20}
                            sx={{ color: 'white' }}
                          />
                        )
                      }>
                      Verify
                    </Button>
                  </Box>

                  <Box>
                    <InputLabel
                      sx={{
                        cursor: 'pointer',
                        color: '#186174'
                      }}
                      disabled={timer !== 0}
                      onClick={linkAccounts}>
                      Didn’t receive OTP? Request another one! {timer !== 0 && `in ${timer}s`}
                    </InputLabel>
                  </Box>
                </Box>
              )
            }}
          />
        </Box>
      )}
    </Paper>
  )
}

export default React.forwardRef<LinkPhoneRef, LinkPhoneProps>((props, ref) => {
  return LinkPhone(props, ref)
})
