import { Box, Tooltip, Typography } from '@mui/material'
import PlatformLogo from 'src/components/PlatformLogo'
import { get } from 'src/utils/config/lodashUtils'
import CustomChip from '../../components/CustomChip'

interface DynamicIconStyledCompProps {
  data: object
  active: boolean
}

export default function DigitalStoresStyledComp(props: DynamicIconStyledCompProps) {
  const { data, active } = props
  const vbName = get(data, 'vb_name', null)
  const vbPlatform = get(data, 'vb_platform', null)
  if (!vbName) {
    return <></>
  }
  return (
    <CustomChip
      active={active}
      showRightArrow
      sx={{ maxWidth: '250px' }}>
      <Tooltip
        arrow
        title={vbName}
        placement="top"
        sx={{ cursor: 'inherit' }}>
        <Box
          sx={{
            minWidth: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '7px'
            // minWidth: '0px',
            // width: '100%'
          }}>
          {vbPlatform && (
            <Box sx={{ minWidth: 0 }}>
              <PlatformLogo
                shortImg
                platformName={vbPlatform}
                width="16px"
                height="16px"
              />
            </Box>
          )}
          <Typography
            variant="subtitle2"
            sx={{ flex: 1, textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {vbName}
          </Typography>
          {/* <ChevronRightIcon sx={{ fontSize: '12px', color: 'black' }} /> */}
        </Box>
      </Tooltip>
    </CustomChip>
  )
}
