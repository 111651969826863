import { doc, onSnapshot } from 'firebase/firestore'
import localforage from 'localforage'
import moment, { Moment } from 'moment'
import React from 'react'
import { createSearchParams } from 'react-router-dom'
import { compareGraphParams, getFilterKeys, getNewLastDate, updateDateRangeFromLastDate } from 'src/components/NewFilterComponent/utils/filterUtils'
import { firebaseDb } from 'src/services/firebase'
import { BusinessRefreshData, DateFilterConfig, FiltersForQuery, ModuleService } from 'src/services/openApi'
import { sendSlackNotification } from 'src/utils/api'
import { AccessLevelEnum, automaticallyUpdateFiltersDuration, swrRefreshIntervalMilliseconds } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { setLocal } from 'src/utils/functions/localStorage'
import useSWR from 'swr'
import { useAuth } from '../AuthContext'
import { useDrillDownContext } from '../DrillDownContext'
import { useErrorData } from '../ErrorContext'
import { useSnackData } from '../SnackContext'
import useFilterDataServiceHook from './hooks/filterDataServiceHook'
import { DateRangeType, FilterGraph } from './utils/FilterGraph'
import filterGraphManager from './utils/FilterGraphManager'
import { getDataFromIndexedDB, saveDataToIndexedDB } from './utils/NewFilterIndexDB'
import { ApiFilterResponseType, BusinessRefreshBnameLevelData } from './utils/types'
import { updateObserverVisibleNodesFromGraph } from './utils/updateObserverFunctions'
import { checkedIfAllOptionsSelected, getFilterDataForUser, getFilterDataSlugs, hasFilterDataChanged } from './utils/validateFilterData'

export interface getFiltersV2ReturnType extends FiltersForQuery {}

type GetFilterValues = <T extends 'array' | 'object'>(
  filterKeys: string[],
  obj?: { dataType?: T; dateWithHyphen?: boolean; emptyForAllSelected?: boolean }
) => T extends 'array' ? string[] : getFiltersV2ReturnType

export interface NewFilterContextInterface {
  businessRefreshBnameLevelData: BusinessRefreshBnameLevelData[]
  userAssignedBnamesJoined: string
  activeGraph: FilterGraph | null
  activeGraphId: string
  mainGraph: FilterGraph
  setActiveGraphId: (graphId: string) => void
  updateActiveGraph: (graphId: string, singleSelectFilterKeys?: string[], allowedFilters?: { [key: string]: (string | number)[] }) => void
  filterConfig: {
    bNameEnabled: boolean
    vbNameEnabled: boolean
    vbPlatformEnabled: boolean
    amNameEnabled: boolean
    unionSearchSelects: boolean
    showNewFilters: boolean
  }
  reRenderVar: number
  reRenderFilterContext: () => void
  dateRange: DateRangeType
  lastDate: Moment
  getFilterValues: GetFilterValues
  getFilters: (filterKeys: string[]) => string[]
  getFiltersV2: (filterList?: string[], dateWithHyphen?: boolean, emptyForAllSelected?: boolean) => getFiltersV2ReturnType
  getAllPossibleFilters: (filterKeys: string[], graphId?: string) => { [key: string]: (string | number)[] }
  getUrlWithFilters: () => string
  selectFiltersFromContext: (filterKey: string, values: (string | number)[]) => void
  refreshFilters: (uid?: string, org?: string) => Promise<void>
  filterDataLoading: boolean
  businessDataLoading: boolean
  isFilterChanged: boolean
  showFilterChangedNotification: boolean
  setShowFilterChangedNotification: React.Dispatch<React.SetStateAction<boolean>>
  updateFiltersWithNewData: (isManualUpdate?: boolean) => void
  autoUpdateFiltersProgress: number
  moduleDateFilterData: DateFilterConfig | null
}

export const NewFilterContext = React.createContext({} as NewFilterContextInterface)

export const useNewFilterContext = () => React.useContext(NewFilterContext)

interface NewFilterContextProviderProps {
  children: React.ReactNode
}

export default function NewFilterContextProvider(props: NewFilterContextProviderProps) {
  const { orgConfig, currentUser, setCurrentUser } = useAuth()
  const email = get(currentUser, 'email', '')
  const org = get(currentUser, 'org', '')

  const { getAllFiltersData, getUserAssignedBNamesData } = useFilterDataServiceHook()
  const {
    getDrillDownFilter: getDrillDownContextFilter,
    setDateRange: setDrillDownDateRange,
    stack: drillDownStack,
    setAllFiltersData: setDrillDownAllFiltersData,
    setBusinessRefreshBnameData: setDrillDownBusinessRefreshBnameData
  } = useDrillDownContext()
  const { openSuccess } = useSnackData()
  const { handleError } = useErrorData()

  // ************************** state variables ***************************
  const [filterDataLoading, setFilterDataLoading] = React.useState<boolean>(true)
  const [businessDataLoading, setBusinessDataLoading] = React.useState<boolean>(true)
  const [isFilterChanged, setIsFilterChanged] = React.useState<boolean>(false)
  const [showFilterChangedNotification, setShowFilterChangedNotification] = React.useState<boolean>(false)
  const [autoUpdateFiltersProgress, setAutoUpdateFiltersProgress] = React.useState<number>(0)
  const [reRenderVar, setReRenderVar] = React.useState(0)
  const [businessRefreshBnameLevelData, setBusinessRefreshBnameLevelData] = React.useState<BusinessRefreshBnameLevelData[]>([])

  const [triggerSwr, setTriggerSwr] = React.useState(0)
  const [moduleDateFilterData, setModuleDateFilterData] = React.useState<DateFilterConfig | null>(null)

  // ************************ SWR hooks *************************
  const fallbackEmptyArrayRef = React.useRef([])

  const { data: allFiltersDataSwr, isLoading: allFiltersDataLoadingSwr } = useSWR(
    ['filtersData', get(currentUser, 'org', ''), triggerSwr],
    ([id, org]) => {
      return getAllFiltersData(org)
    },
    {
      refreshInterval: swrRefreshIntervalMilliseconds,
      revalidateOnFocus: false,
      fallbackData: fallbackEmptyArrayRef.current,
      errorRetryCount: 1
    }
  )
  const { data: userAssignedBnamesSwr, isLoading: userAssignedBnamesLoadingSwr } = useSWR(
    ['userAssignedBnames', get(currentUser, 'uid', ''), triggerSwr],
    ([id]) => {
      return getUserAssignedBNamesData()
    },
    {
      refreshInterval: swrRefreshIntervalMilliseconds,
      revalidateOnFocus: false,
      fallbackData: fallbackEmptyArrayRef.current,
      errorRetryCount: 1
    }
  )

  const allFiltersDataForUser = React.useMemo(() => {
    return getFilterDataForUser(
      allFiltersDataSwr,
      userAssignedBnamesSwr,
      [AccessLevelEnum.BUSINESS_ADMIN, AccessLevelEnum.FINANCE_TEAM].includes(getAccessLevel(currentUser))
    )
  }, [allFiltersDataSwr, userAssignedBnamesSwr])

  const userAssignedBnamesJoined = React.useMemo(() => {
    const accessLevel = getAccessLevel(currentUser)
    if (accessLevel === AccessLevelEnum.BUSINESS_ADMIN || accessLevel === AccessLevelEnum.FINANCE_TEAM) {
      return ''
    }
    return userAssignedBnamesSwr?.map((obj) => obj.name).join('|')
  }, [userAssignedBnamesSwr])

  React.useEffect(() => {
    if (userAssignedBnamesLoadingSwr || allFiltersDataLoadingSwr) {
      return
    }
    if (currentUser) {
      checkFilterDataChanged()
    }
  }, [userAssignedBnamesLoadingSwr, allFiltersDataLoadingSwr, currentUser])

  // ? Active graph key from FilterGraphManager
  const activeGraphIdRef = React.useRef('main')
  const activeGraphId = activeGraphIdRef.current
  let activeGraph = filterGraphManager.getGraph(activeGraphId)
  const setActiveGraphId = (graphId: string) => {
    activeGraphIdRef.current = graphId
    activeGraph = filterGraphManager.getGraph(graphId)
  }
  const mainGraph = filterGraphManager.getGraph('main')

  const filterConfig: NewFilterContextInterface['filterConfig'] = React.useMemo(() => {
    const bNameEnabled: boolean = get(orgConfig, 'filterConfig.b_name', false)
    const vbNameEnabled: boolean = get(orgConfig, 'filterConfig.vb_name', false)
    const vbPlatformEnabled: boolean = get(orgConfig, 'filterConfig.vb_platform', false)
    const amNameEnabled: boolean = get(orgConfig, 'filterConfig.am_name', false)
    const unionSearchSelects: boolean = false && !get(orgConfig, 'filterConfig.search_select', false)
    const showNewFilters: boolean = true

    return {
      bNameEnabled,
      vbNameEnabled,
      vbPlatformEnabled,
      amNameEnabled,
      unionSearchSelects,
      showNewFilters
    }
  }, [orgConfig])

  /**
   * this function will refresh the FilterContext because
   * change in FilterGraph instance does not re-renders the context
   */
  const reRenderFilterContext = () => {
    if (activeGraphId === 'main') {
      const defaultFilterKeys = getFilterKeys()
      const selectedFilterValuesJoined = {}
      const result = getFiltersV2(defaultFilterKeys)
      defaultFilterKeys.forEach((key) => {
        selectedFilterValuesJoined[key] = result[`${key}_in`]
      })
      saveSelectFiltersToSessionStorage(JSON.stringify(selectedFilterValuesJoined))
      // saveDataToIndexedDB('SelectedFilters', selectedFilterValuesJoined)
    }
    setReRenderVar((prev) => prev + 1)
  }

  // *************************** graph actions ****************************
  /**
   * this function will add the FilterGraph instance to filterGraphManager
   * @param graphId
   * @param param
   */
  const setGraphToManager = (
    graphId: string,
    data: ApiFilterResponseType[],
    singleSelectFilterKeys?: string[],
    allowedFilters?: { [key: string]: (string | number)[] }
  ) => {
    const filterData = [...data]
    filterGraphManager.setGraph(graphId, filterData, businessRefreshBnameLevelData, singleSelectFilterKeys, allowedFilters)
  }

  const updateActiveGraph = (graphId: string, singleSelectFilterKeys?: string[], allowedFilters?: { [key: string]: (string | number)[] }) => {
    // ? check if graph with id already exists, and special params have not changed
    if (
      filterGraphManager.getGraphIds().includes(graphId) &&
      compareGraphParams(filterGraphManager.getParams(graphId), singleSelectFilterKeys, allowedFilters)
    ) {
      setActiveGraphId(graphId)
      return
    }
    let filtersData = allFiltersDataForUser
    if (allowedFilters) {
      filtersData = filtersData.filter((obj) => {
        return Object.keys(allowedFilters).every((allowedKey) => allowedFilters[allowedKey].includes(obj[allowedKey]))
      })
    }
    setGraphToManager(graphId, filtersData, singleSelectFilterKeys, allowedFilters)
    setActiveGraphId(graphId)
    reRenderFilterContext()
  }

  const createGraphInstances = (propAllFilterDataForUser?: ApiFilterResponseType[]) => {
    const allFiltersDataUserLevel = propAllFilterDataForUser ? propAllFilterDataForUser : allFiltersDataForUser

    const allGraphIds = filterGraphManager.getGraphIds()

    if (allGraphIds.length === 0) {
      setGraphToManager('main', allFiltersDataUserLevel)
    } else {
      // ? update each instance with new data
      allGraphIds.forEach((graphId) => {
        // ? save special params
        const specialParams = filterGraphManager.getParams(graphId)
        // ? save selected state
        const selectedValues = {}
        const graph = filterGraphManager.getGraph(graphId)
        Object.keys(graph.getFilters()).forEach((key) => {
          if (graph.getSelectedNodesByKey(key).size === graph.getAllPossibleNodesByKey(key).length) return
          graph.getSelectedNodesByKey(key).forEach((node) => {
            if (key in selectedValues) {
              selectedValues[key].push(node.value)
            } else {
              selectedValues[key] = [node.value]
            }
          })
        })
        // ? create new graph with params
        let filtersData = allFiltersDataUserLevel
        if (specialParams?.allowedFilters) {
          filtersData = filtersData?.filter((obj) => {
            return Object.keys(specialParams.allowedFilters)?.every((allowedKey) => specialParams.allowedFilters[allowedKey]?.includes(obj[allowedKey]))
          })
        }
        setGraphToManager(graphId, filtersData, specialParams.singleSelectFilterKeys, specialParams.allowedFilters)
        // ? restore selected state
        Object.keys(selectedValues).forEach((key) => {
          if (key !== 'b_name_id') selectFiltersFromContext(key, selectedValues[key], graphId)
        })
      })
    }
    filterGraphManager.setFilterDataSlugs(getFilterDataSlugs(allFiltersDataForUser))

    reRenderFilterContext()
    setFilterDataLoading(false)
    saveDataToIndexedDB('AllFiltersData', allFiltersDataUserLevel)
  }

  // ********************* business data fetching ******************************
  const getBusinessRefreshBnameLevelData = (businessData: BusinessRefreshData[]) => {
    try {
      const data = businessData.map((item) => {
        return {
          ...item,
          success_rate: item.success_rate || 0,
          lastDate: item.last_date
        }
      })
      if (data && Array.isArray(data) && data.length > 0) {
        setBusinessRefreshBnameLevelData(data)
        const graphIds = filterGraphManager.getGraphIds()
        graphIds.forEach((graphId) => {
          const graph = filterGraphManager.getGraph(graphId)
          const newLastDate = getNewLastDate(data, graph)
          graph.setLastDate(newLastDate)
          updateDateRangeFromLastDate(graph, newLastDate, true)
        })
      }
    } catch (err) {
      handleError(err.message)
    }
  }

  const fetchFiltersDataFromLocalAndAPI = (org?: string) => {
    getFiltersDataFromIndexedDb()
  }

  // ? first load
  React.useEffect(() => {
    fetchFiltersDataFromLocalAndAPI()
  }, [])

  // ? after org change
  React.useEffect(() => {
    let unsubscribe
    if (currentUser && currentUser.org) {
      const query = doc(firebaseDb, `users/${currentUser.uid}`)
      unsubscribe = onSnapshot(query, (snapshot) => {
        const data = snapshot.data()

        if (!data) return

        const currentUserAccessLevel = getAccessLevel(currentUser)
        const dataAccessLevel = getAccessLevel(data)
        if (data?.org !== currentUser?.org || dataAccessLevel !== currentUserAccessLevel) {
          setCurrentUser({
            ...currentUser,
            ...data
          })
          setLocal('userOrg', data.org)
          refreshFilters()
        }
      })
    }

    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [currentUser])

  // ********************** swr data update handlers *************************
  const checkFilterDataChanged = () => {
    if (filterGraphManager.getFilterDataSlugs().size === 0) {
      createGraphInstances()
    }
    if (hasFilterDataChanged(allFiltersDataForUser, filterGraphManager.getFilterDataSlugs())) {
      setIsFilterChanged(true)
      setShowFilterChangedNotification(true)
      sendSlackNotification({
        message: 'Filters data change detected',
        channel: 'fe-logs',
        title:
          `Filters data changed for\n` +
          (org ? `\`org: ${org}\`\n` : ``) +
          (email ? `\`user: ${email}\`\n` : ``) +
          `\`page URL: <${window.location.href}|${window.location.pathname}>\`\n`
      })
      setTimeout(() => {
        updateFiltersWithNewData()
      }, automaticallyUpdateFiltersDuration)
    }
  }

  const updateFiltersWithNewData = (isManualUpdate?: boolean) => {
    setFilterDataLoading(true)
    createGraphInstances()
    setIsFilterChanged(false)
    setShowFilterChangedNotification(false)
    openSuccess('Filters have been updated')

    const message = 'Filters update success'
    let title = `Filters updated ${isManualUpdate ? 'manually' : 'automatically'} for\n`
    title =
      title + (org ? `\`org: ${org}\`\n` : ``) + (email ? `\`user: ${email}\`\n` : ``) + `\`page URL: <${window.location.href}|${window.location.pathname}>\`\n`
    sendSlackNotification({
      message,
      title,
      channel: 'fe-logs'
    })
  }

  // ? handle filter update progress timer
  React.useEffect(() => {
    let timer
    if (isFilterChanged) {
      setAutoUpdateFiltersProgress(100)
      timer = setInterval(() => {
        setAutoUpdateFiltersProgress((prevProgress) => (prevProgress < 0 ? 100 : prevProgress - 10))
      }, automaticallyUpdateFiltersDuration / 10)
    }
    return () => {
      clearInterval(timer)
    }
  }, [isFilterChanged])

  const getFilterValues: GetFilterValues = <T extends 'array' | 'object'>(
    filterKeys: string[],
    obj: { dataType?: T; dateWithHyphen?: boolean; emptyForAllSelected?: boolean }
  ): T extends 'array' ? string[] : getFiltersV2ReturnType => {
    // function getFilterValues(filterKeys: string[], obj?: { dataType: 'array'; dateWithHyphen?: boolean; emptyForAllSelected?: boolean }): string[]
    // function getFilterValues(filterKeys: string[], obj?: { dataType?: 'object'; dateWithHyphen?: boolean; emptyForAllSelected?: boolean }): getFiltersV2ReturnType
    // function getFilterValues(filterKeys: string[], obj?: { dataType?: 'array' | 'object'; dateWithHyphen?: boolean; emptyForAllSelected?: boolean }) {
    const { dataType, dateWithHyphen, emptyForAllSelected = true } = obj || {}
    const graphFilters = activeGraph.getFilters()
    const result = [] as { key: string; value: string | boolean; forArrayOnly?: boolean; extraForObj?: { key: string; value: string | boolean }[] }[]
    filterKeys.forEach((key) => {
      const drillDownContextValue = getDrillDownContextFilter ? getDrillDownContextFilter(key) : null
      if (drillDownContextValue) {
        result.push({ key: `${key}_in`, value: drillDownContextValue })
        return
      }
      if (key in graphFilters) {
        let value: string
        if (allFiltersDataForUser.length === allFiltersDataSwr.length && emptyForAllSelected && checkedIfAllOptionsSelected(activeGraphId, key)) {
          value = ''
        } else {
          const intersectionSet = activeGraph.getVisibleSelectedIntersectionNodes(key)
          const intersectionArr = [...intersectionSet]
          value = intersectionArr.map((e) => e.value).join('|')
        }
        if (key === 'b_name_id') {
          result.push({ key: 'b_name_ids_in', value })
        } else {
          result.push({ key: `${key}_in`, value })
        }
      } else if (key === 'platform') {
        const intersectionSet = activeGraph.getVisibleSelectedIntersectionNodes('vb_platform')
        const intersectionArr = [...intersectionSet]
        const value = intersectionArr.map((e) => e.value).join('|')
        result.push({ key: 'vb_platform_in', value })
      } else if (key === 'chain') {
        result.push({ key: `${key}_in`, value: get(currentUser, 'org', '') })
      } else if (key === 'start_date') {
        result.push({ key: `${key}_in`, value: dateRange?.start.format(dateWithHyphen ? 'YYYY-MM-DD' : 'YYYYMMDD') || '' })
      } else if (key === 'end_date') {
        result.push({ key: `${key}_in`, value: dateRange?.end.format(dateWithHyphen ? 'YYYY-MM-DD' : 'YYYYMMDD') || '' })
      } else if (key === 'granularity') {
        let start_date = dateRange?.start
        let end_date = dateRange?.end
        if (start_date && end_date) {
          result.push({
            key: `${key}_in`,
            value: findGranularityByDates({ start_date, end_date }),
            extraForObj: [{ key: 'should_adjust_dates', value: true }]
          })
        }
      } else if (key.length > 0 && key.startsWith('_')) {
        result.push({ key: '', value: key.slice(1), forArrayOnly: true })
      }
    })

    type Type = T extends 'array' ? string[] : getFiltersV2ReturnType

    if (dataType === 'array') {
      return result.map(({ value }) => value.toString()) as Type
    }

    const objResult = {} as getFiltersV2ReturnType
    result.forEach(({ key, value, forArrayOnly, extraForObj }) => {
      if (forArrayOnly) {
        return
      }
      objResult[key] = value
      if (extraForObj) {
        extraForObj.forEach(({ key, value }) => {
          objResult[key] = value
        })
      }
    })
    return objResult as Type
  }

  // ********************* data exposed by provider ***********************
  /**
   * This function will take the list of keys (like ['b_name', 'vb_name', 'vb_platform']).
   * User can also pass custom value in keys followed by an '_' (like ['b_name', '_some_customValue', 'vb_name'])
   * @param filterKeys
   * @returns filter values and other data in an array
   */
  const getFilters = (filterKeys: string[]) => {
    return getFilterValues(filterKeys, { dataType: 'array' })
  }

  /**
   * This function will take the list of keys (like ['b_name', 'vb_name', 'vb_platform']).
   * @param filterKeys
   * @returns filter values and other data in an object
   */
  const getFiltersV2 = (filterKeys: string[], dateWithHyphen?: boolean, emptyForAllSelected: boolean = true) => {
    return getFilterValues(filterKeys, { dataType: 'object', dateWithHyphen, emptyForAllSelected })
  }

  const getAllPossibleFilters = (filterKeys: string[], graphId?: string) => {
    const graph = graphId ? filterGraphManager.getGraph(graphId) : activeGraph
    const result = {}
    filterKeys.forEach((key) => {
      const allPossibleNodes = graph.getAllPossibleNodesByKey(key)
      const value = allPossibleNodes.map((e) => e.value)
      result[key] = value
    })
    return result
  }

  const getUrlWithFilters = () => {
    const defaultFilterKeys = getFilterKeys()
    const selectedFilterValuesJoined = {}
    const result = getFiltersV2(defaultFilterKeys)
    defaultFilterKeys.forEach((key) => {
      selectedFilterValuesJoined[key] = result[`${key}_in`]
    })
    const params = createSearchParams({
      filters: JSON.stringify(selectedFilterValuesJoined),
      start_date: dateRange?.start.format('YYYYMMDD') || '',
      end_date: dateRange?.end.format('YYYYMMDD') || ''
    })

    let url = new URL(window.location.href)
    params.forEach((param, key) => {
      if (url.searchParams.has(key)) {
        url.searchParams.delete(key)
      }
      url.searchParams.set(key, param)
    })

    return url.href
  }

  // ************************** actions exposed by provider *****************************
  async function refreshFilters() {
    localforage.clear()
    filterGraphManager.clearGraphs()
    setTriggerSwr((prev) => prev + 1)
  }

  const selectFiltersFromContext = (filterKey: string, values: (string | number)[], graphId?: string) => {
    const graph = graphId ? filterGraphManager.getGraph(graphId) : activeGraph
    const selectedFilterNodes = graph.getAllPossibleNodesByKey(filterKey).filter((node) => values.includes(node.value))
    // ? if key is single select and no matching values are found, then select first value
    if (selectedFilterNodes.length === 0 && graph.getSingleSelectByKey(filterKey)) {
      selectedFilterNodes.push(graph.getAllPossibleNodesByKey(filterKey)[0])
    }
    graph.setSelected(filterKey, selectedFilterNodes)

    const defaultFilterKeys = getFilterKeys()
    defaultFilterKeys.forEach((observerFilterKey) => {
      if (observerFilterKey !== filterKey) updateObserverVisibleNodesFromGraph(observerFilterKey, selectedFilterNodes, graph)
    })
    reRenderFilterContext()
  }

  // ************** dates related code *****************
  React.useEffect(() => {
    setDrillDownAllFiltersData(allFiltersDataForUser)
  }, [allFiltersDataForUser])

  React.useEffect(() => {
    setDrillDownBusinessRefreshBnameData(businessRefreshBnameLevelData)
  }, [businessRefreshBnameLevelData])

  const dateRange = activeGraph.getDateRange()
  const lastDate = activeGraph.getLastDate()

  let findGranularityByDates = React.useCallback(
    function ({ start_date, end_date }: { start_date: Moment; end_date: Moment }) {
      let diff = moment(end_date).diff(moment(start_date), 'days')
      if (diff > 180) {
        return 'month'
      } else if (diff > 50) {
        return 'week'
      } else {
        return 'day'
      }
    },
    [...getFilters(['start_date', 'end_date'])]
  )

  // ************************** client side storage ********************************
  const saveSelectFiltersToSessionStorage = (serializedSelectedFilters: string) => {
    sessionStorage.setItem('selectedFilters', serializedSelectedFilters)
    sessionStorage.setItem('start_date', dateRange?.start.format('YYYYMMDD') || '')
    sessionStorage.setItem('end_date', dateRange?.end.format('YYYYMMDD') || '')
  }

  const getFiltersDataFromIndexedDb = async () => {
    try {
      const filtersDataFromIndexedDB = await getDataFromIndexedDB('AllFiltersData')
      if (filtersDataFromIndexedDB && filtersDataFromIndexedDB.length > 0) {
        setGraphToManager('main', filtersDataFromIndexedDB)
        setFilterDataLoading(false)
      }
    } catch (err) {
      handleError("Can't fetch Filters Data from IDB: " + err.message)
    }
  }

  // *********************** drill down related code *************************
  React.useEffect(() => {
    if (drillDownStack.length > 0) {
      return
    }
    const { start_date_in: start, end_date_in: end } = getFiltersV2(['start_date', 'end_date'], true)
    if (start && end) {
      setDrillDownDateRange({ start: moment(start), end: moment(end) })
    }
  }, [drillDownStack, ...getFilters(['start_date', 'end_date'])])

  React.useEffect(() => {
    if (!currentUser?.org) return

    const getModuleDateFilterData = async () => {
      try {
        setBusinessDataLoading(true)

        const requestBody = {
          chain_in: currentUser.org,
          b_name_in: ''
        }
        const res = await ModuleService.getDateFilterConfigModuleDateFilterConfigPost({
          ...requestBody
        })
        setModuleDateFilterData(res)
        getBusinessRefreshBnameLevelData(res.business_refresh)
      } catch (err) {
        console.error('Error in module date filter data', err)
        handleError('Error in module date filter data')
      } finally {
        setBusinessDataLoading(false)
      }
    }

    getModuleDateFilterData()
  }, [currentUser?.org])

  const contextValue = React.useMemo(() => {
    return {
      businessRefreshBnameLevelData,
      userAssignedBnamesJoined,
      updateActiveGraph,
      activeGraph,
      activeGraphId,
      setActiveGraphId,
      mainGraph,
      filterConfig,
      reRenderVar,
      reRenderFilterContext,
      dateRange,
      lastDate,
      getFilterValues,
      getFilters,
      getFiltersV2,
      getAllPossibleFilters,
      getUrlWithFilters,
      selectFiltersFromContext,
      refreshFilters,
      filterDataLoading,
      businessDataLoading,
      isFilterChanged,
      showFilterChangedNotification,
      setShowFilterChangedNotification,
      updateFiltersWithNewData,
      autoUpdateFiltersProgress,
      moduleDateFilterData
    }
  }, [
    businessRefreshBnameLevelData,
    userAssignedBnamesJoined,
    updateActiveGraph,
    activeGraph,
    activeGraphId,
    mainGraph,
    filterConfig,
    reRenderVar,
    dateRange,
    lastDate,
    getFilterValues,
    getFilters,
    getFiltersV2,
    getAllPossibleFilters,
    getUrlWithFilters,
    selectFiltersFromContext,
    refreshFilters,
    filterDataLoading,
    businessDataLoading,
    isFilterChanged,
    showFilterChangedNotification,
    setShowFilterChangedNotification,
    updateFiltersWithNewData,
    autoUpdateFiltersProgress,
    moduleDateFilterData
  ])

  return <NewFilterContext.Provider value={contextValue}>{props.children}</NewFilterContext.Provider>
}
