import CustomMaterialTable from '@LoopKitchen/loop-ui/CustomMaterialTable'
import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import React, { useState } from 'react'
import { DefaultService, PosPayouts, PosTransactionsForPayoutFiltersPaginated, PosTransactionsForPayoutPaginated } from 'src/services/openApi'
import { Modules } from 'src/utils/config/config'
import { downloadFailedSlackAlert, downloadSuccessSlackAlert } from 'src/utils/functions/slackSupport'
import DeltaCard from '../../../components/DeltaCard'
import { exportCSV } from '../../../components/ExportCsvV2'
import { StringShortener } from '../../../components/StringShortener'
import { useErrorData } from '../../../context/ErrorContext'
import { useFilter } from '../../../context/FilterContext'
import { useSnackData } from '../../../context/SnackContext'
import { formatCurrency } from '../../../utils/functions'
import usePostHogHook from '../../../utils/hooks/usePostHogHook'

export default function PayoutDetail(props: { payout_line_item: PosPayouts }) {
  const { getFilters, getFiltersV2 } = useFilter()
  const { handleError } = useErrorData()
  const { trackPostHogDownload } = usePostHogHook()
  const [dataLoading, setDataLoading] = React.useState(false)
  const [tableData, setTableData] = React.useState<PosTransactionsForPayoutPaginated>(null)
  const { setDownloadSnack } = useSnackData()
  const [loadingNextPage, setLoadingNextPage] = useState(false)

  const requestBodyGetter = (): PosTransactionsForPayoutFiltersPaginated => {
    const filters = getFiltersV2(['chain'], true)
    let request: PosTransactionsForPayoutFiltersPaginated = {
      ...filters
    }
    request.payout_id = props.payout_line_item.payout_id
    request.limit = 100
    request.offset = tableData?.next_offset || 0
    return request
  }

  const getTableData = async (paginate: boolean = false) => {
    setDataLoading(true)
    setLoadingNextPage(paginate)
    try {
      const data: PosTransactionsForPayoutPaginated = await DefaultService.callPosListTransactionsForPayoutApiPaginatedPosListTransactionsForPayoutPost(
        {
          ...requestBodyGetter(),
          limit: 100,
          offset: tableData?.next_offset || 0
        },
        true
      )
      if (paginate) {
        data.data = [...tableData.data, ...data.data]
      }
      setTableData(data)
      setDataLoading(false)
      setLoadingNextPage(false)
    } catch (err) {
      handleError(err.body)
    }
  }

  React.useEffect(() => {
    getTableData()
  }, [...getFilters(['chain']), props.payout_line_item.payout_id])
  return (
    <Stack spacing={2}>
      {' '}
      <Box
        component="span"
        sx={{ display: 'flex', alignItems: 'center' }}>
        Payout ID :
        <StringShortener
          str={props.payout_line_item.payout_id}
          displayLength={10}
          allowClickToCopy={true}
        />
      </Box>
      <Stack
        direction="row"
        spacing={1}>
        <DeltaCard
          title={`${formatCurrency(props.payout_line_item.payout_amount)}`}
          label="Total Payout"
          bigFont
          sx={{ height: '100px' }}
        />
        <DeltaCard
          title={`${formatCurrency(props.payout_line_item.bank_amount)}`}
          label="Amount in Bank"
          bigFont
          sx={{ height: '100px' }}
        />
        <DeltaCard
          title={`${formatCurrency(props.payout_line_item.transactions_derived_amount)}`}
          label="Transactions summary"
          bigFont
          sx={{ height: '100px' }}
        />
        <DeltaCard
          title={`${formatCurrency(props.payout_line_item.payout_transaction_variance)}`}
          label="Payout Transaction Variance"
          bigFont
          sx={{ height: '100px' }}
        />
        <DeltaCard
          title={`${formatCurrency(props.payout_line_item.payout_bank_variance)}`}
          label="Payout Bank Variance"
          bigFont
          sx={{ height: '100px' }}
        />
      </Stack>
      <CustomMaterialTable
        data={tableData?.data || []}
        columns={[
          {
            title: 'Order ID',
            field: 'order_id',
            render: (rowData) => {
              return (
                <StringShortener
                  str={rowData.order_id}
                  platform="toast"
                  allowClickToCopy={true}
                />
              )
            }
          },
          {
            title: 'Order Date',
            field: 'order_date',

            render: (rowData) => {
              //     2023-08-30 16:28:00+00 => 2023-08-30
              return <span>{rowData.order_date?.split(' ')[0]}</span>
            }
          },
          {
            title: 'Subtotal',
            field: 'subtotal',
            render: (rowData) => formatCurrency(rowData.subtotal)
          },
          {
            title: 'Tip',
            field: 'tip',
            render: (rowData) => formatCurrency(rowData.tip)
          },
          {
            title: 'Total',
            field: 'total',
            render: (rowData) => formatCurrency(rowData.total)
          },
          {
            title: Modules.Chargebacks,
            field: 'chargeback',
            render: (rowData) => formatCurrency(rowData.chargeback)
          },
          {
            title: 'Commission',
            field: 'commission',
            render: (rowData) => formatCurrency(rowData.commission)
          },
          {
            title: 'Net Payout',
            field: 'net_payout',
            render: (rowData) => formatCurrency(rowData.net_payout)
          },
          {
            title: 'Transaction Date',
            field: 'transaction_date',
            render: (rowData) => {
              return <span>{rowData.order_date?.split(' ')[0]}</span>
            }
          }
        ]}
        onExportCSV={() => {
          exportCSV({
            apiCall: DefaultService.exportPosListTransactionsForPayoutApiExportPosListTransactionsForPayoutPost,
            requestBody: requestBodyGetter(),
            onStart: () => setDownloadSnack({ status: 'start' }),
            onComplete: () => {
              setDownloadSnack({ status: 'complete' })
              downloadSuccessSlackAlert({ apiUrl: '/api/export/pos/list_transactions_for_payout' })
            },
            onError: (errMessage: string) => {
              setDownloadSnack({ status: 'close' })
              handleError(errMessage)
              downloadFailedSlackAlert({ err: new Error(errMessage), apiUrl: '/api/export/pos/list_transactions_for_payout' })
            },
            trackDownload: (details: { fileName: string; type: 'CSV' | 'PDF' }) => {
              trackPostHogDownload(details)
            }
          })
        }}
        isLoading={dataLoading}
        options={{
          stickyHeader: '0px',
          export: true,
          search: false,
          pagination: true,
          toolbarSx: { pb: 2, pt: 0, pl: 0 },
          totalPaginatedDataLength: tableData?.max_rows || 0,
          loadingNextPage: loadingNextPage,
          debounceMilliseconds: 1500
        }}
        onRefresh={() => getTableData(false)}
        onLastPage={async () => {
          if (tableData?.next_offset) {
            await getTableData(true)
          }
        }}
      />
    </Stack>
  )
}
