import { SvgIcon, SvgIconProps } from '@mui/material'
import { get } from 'src/utils/config/lodashUtils'

export default function HamburgerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="36"
        height="18"
        viewBox="0 0 36 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0.846802"
          y="0.941528"
          width="35.1052"
          height="2.85107"
          rx="1.42554"
          fill={get(props, 'sx.color', '#000000') as string}
        />
        <rect
          x="0.846802"
          y="14.3199"
          width="20.8811"
          height="2.85107"
          rx="1.42554"
          fill={get(props, 'sx.color', '#000000') as string}
        />
      </svg>
    </SvgIcon>
  )
}
