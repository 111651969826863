import AggregateCard from '@LoopKitchen/loop-ui/Card/AggregateCard'
import BarChart from '@LoopKitchen/loop-ui/Charts/BarChart'
import Paper from '@LoopKitchen/loop-ui/Paper'
import { MonetizationOn, PointOfSale, Rule } from '@mui/icons-material'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Tab, { TabProps } from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import NoDataImg from 'src/assets/images/no_data.png'
import EmptyScreen from 'src/components/EmptyScreen/EmptyScreen'
import ErrorRateMessage from 'src/components/ErrorRateMessages'
import ComponentLoader from 'src/components/Loading/ComponentLoader'
import TabPanel from 'src/components/TabPanel'
import { filterNames } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { emptyScreenMessages } from 'src/utils/constants/emptyScreen'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import useTabUrlState from 'src/utils/hooks/useTabUrlState'
import HostKitchenTable from '../ErrorOverview/HostKitchenTable'
import PlatformChart from '../ErrorOverview/PlatformChart'
import VirtualBrandTable from '../ErrorOverview/VirtualBrandTable'
import HostKitchenTable2 from './HostKitchenTable'
import PlatformChart2 from './PlatformChart'
import VirtualBrandTable2 from './VirtualBrandTable'

export const primaryBarColor = '#196E82'
export const secondaryBarColor = '#F15413CC'
export const secondaryTextColor = '#F15413CC'

interface OverviewProps {
  name?: string
  tabs?: Array<{
    label: string
    value: number
  }>
  indicator?: {
    sum: string
    name: string
    startDate: string
    endDate: string
    startDate2?: string
    endDate2?: string
    sumColor?: string
    delta?: number
    deltaColor?: 'success' | 'error' | 'zero'
    deltaTooltip?: string | JSX.Element
  }
  procedureNameArray?: Array<string>
  graphData?: Array<{}>
  graphDataLoading?: boolean
  linkTab?: number
  handleLinkTab?: (event: React.SyntheticEvent, newValue: number) => void
  setGraphDataLoading?: (params: boolean) => any
  sectionLink: string
  type?: string
  message?: string
  chartClickable?: boolean
}

interface StyledTabProps extends TabProps {
  component?: typeof Link
  to?: string
}
interface ValueContainerProps {
  title: string | number
  value: string | number
  delta?: string | number
  deltaColor?: string
}

interface DateRangeDispayProps {
  startOne: string
  startTwo: string
  endOne: string
  checked: boolean
  endTwo: string
}

export function DateRangeDisplay(props: DateRangeDispayProps) {
  return (
    <Stack
      sx={{
        display: 'inline-block',
        minWidth: '10%',
        mb: '32px'
      }}>
      {props.startOne && props.endOne && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            textAlign: 'center'
          }}>
          <HorizontalRuleIcon sx={{ color: primaryBarColor, fontSize: '2.5vw', mr: '4px' }} />
          <Typography fontSize={'14px'}>
            {props.startOne} - {props.endOne} (current selection date)
          </Typography>
        </Box>
      )}
      {props.checked && props.startTwo && props.endTwo && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            textAlign: 'left'
          }}>
          <HorizontalRuleIcon sx={{ color: secondaryBarColor, fontSize: '2.5vw', mr: '4px' }} />
          <Typography fontSize={'14px'}>
            {props.startTwo} - {props.endTwo} (previous prior to selection date)
          </Typography>
        </Box>
      )}
    </Stack>
  )
}

export function ValueContainer(props: ValueContainerProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        p: '20px',
        border: 'solid 1px rgba(230, 230, 230, 0.9)',
        borderRadius: '4px',
        width: 'fit-content',
        my: '20px'
      }}>
      <Typography
        fontSize={'16px'}
        color={'#425866'}>
        {props.title}
      </Typography>
      <Typography
        fontSize={'24px'}
        color={'#2f4450'}
        fontWeight={600}>
        {props.value}
      </Typography>
      {props.delta && (
        <Typography
          fontSize={'12px'}
          color={props.deltaColor}>
          {props.delta}
        </Typography>
      )}
    </Box>
  )
}

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
    display: 'none'
  },
  backgroundColor: '#EBECF2',
  minHeight: 0,
  height: 'auto',
  padding: 2,
  borderRadius: 4,
  overflow: 'hidden'
})

const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)(({ theme }) => ({
  margin: 0,
  minHeight: 0,
  color: '#696C80',
  '&:hover': { opacity: 1 },
  backgroundColor: '#EBECF2',
  textTransform: 'uppercase',
  '&.MuiTab-root': { maxHeight: 35 },
  fontWeight: theme.typography.fontWeightMedium,
  '&.Mui-focusVisible': { backgroundColor: '#d1eaff' },
  '&.Mui-selected': { color: 'black', backgroundColor: 'white' }
}))

const CustomTooltip = ({ active, payload, label, checked, showDollar, name = 'Value' }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box sx={{ bgcolor: 'white', borderRadius: '5px' }}>
        <Table>
          <TableHead
            sx={{
              background: '#eee'
            }}>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Date</Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="subtitle2"
                  textAlign="right">
                  {name}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {checked && payload[1]?.payload && (
              <TableRow sx={{ color: secondaryTextColor }}>
                <TableCell sx={{ color: 'inherit' }}>
                  <Typography
                    variant="subtitle2"
                    color="inherit">
                    {get(payload, '[1].payload.prevDate')}
                  </Typography>
                </TableCell>
                <TableCell sx={{ color: 'inherit' }}>
                  <Typography
                    variant="subtitle2"
                    color="inherit"
                    textAlign={'right'}
                    fontWeight={500}>
                    {get(payload, '[1].payload.prevValue') === -1
                      ? 'N/A'
                      : showDollar
                        ? formatCurrency(get(payload, '[1].payload.prevValue'), { maxFractionDigits: 2 })
                        : get(payload, '[1].payload.prevValue')?.toFixed(0)}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow sx={{ color: primaryBarColor }}>
              <TableCell sx={{ color: 'inherit' }}>
                <Typography
                  variant="subtitle2"
                  color="inherit">
                  {get(payload, '[0].payload.currentDate')}
                </Typography>
              </TableCell>
              <TableCell sx={{ color: 'inherit' }}>
                <Typography
                  variant="subtitle2"
                  color="inherit"
                  textAlign={'right'}
                  fontWeight={500}>
                  {get(payload, '[0].payload.currentValue') === -1
                    ? 'N/A'
                    : showDollar
                      ? formatCurrency(get(payload, '[0].payload.currentValue'), { maxFractionDigits: 2 })
                      : get(payload, '[0].payload.currentValue')?.toFixed(0)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    )
  }
  return null
}

const SalesOverview = (props: OverviewProps) => {
  const [value, setValue] = useTabUrlState(`overview_sales_${props.name}`, 0)
  const [checked, setChecked] = React.useState(false)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  let deltaDownColors = {
    background: '#FFE5E5',
    color: 'red'
  }
  let deltaUpColors = {
    background: '#E1FFB1',
    color: 'green'
  }
  const deltaZero = {
    background: 'rgba(128,128,128,0.25)',
    color: '#000'
  }

  const { hasCurrData, hasPrevData } = React.useMemo(() => {
    if (!Array.isArray(props.graphData) || props.graphData.length === 0) {
      return {
        hasCurrData: false,
        hasPrevData: false
      }
    }
    const hasPrevData = props.graphData.some((item: any) => typeof item.prevDate === 'string' && ['string', 'number'].includes(typeof item.prevValue))
    const hasCurrData = props.graphData.some((item: any) => typeof item.currentDate === 'string' && ['string', 'number'].includes(typeof item.currentValue))
    return { hasCurrData, hasPrevData }
  }, [props.graphData])

  const bars = React.useMemo(() => {
    const arr = [
      {
        type: 'monotone',
        dataKey: 'currentValue',
        fill: primaryBarColor,
        stroke: primaryBarColor
      }
    ]
    if (checked) {
      arr.unshift({
        type: 'monotone',
        dataKey: 'prevValue',
        fill: secondaryBarColor,
        stroke: secondaryBarColor
      })
    }
    return arr
  }, [checked])

  const tickInterval: number = React.useMemo(() => {
    return Math.floor(props.graphData.length / 10)
  }, [props.graphData])

  return (
    <Paper sx={{ p: 2, pb: 0 }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={'flex-start'}
        gap={2}
        mb={2}>
        <Box display="inline-block">
          <Tabs
            value={props.linkTab}
            onChange={props.handleLinkTab}>
            {props.tabs.map((item) => (
              <Tab
                id={item.label}
                key={item.value}
                value={item.value}
                label={item.label}
              />
            ))}
          </Tabs>
        </Box>
        <Box
          display="flex"
          flexDirection={'row'}
          alignItems={'center'}
          gap="10px">
          <Typography>Show {props.indicator.name} by</Typography>
          <Box sx={{ display: 'inline-block' }}>
            <StyledTabs
              value={value}
              onChange={handleChange}>
              {['Date', filterNames.b_name, filterNames.vb_platform].map((item, index) => (
                <StyledTab
                  id={item}
                  key={item}
                  value={item === filterNames.vb_platform ? 3 : index}
                  label={item.toUpperCase()}
                />
              ))}
            </StyledTabs>
          </Box>
        </Box>
      </Box>
      {value === 0 &&
        (props.graphDataLoading ? (
          <Box sx={{ width: '100%', textAlign: 'center', p: 3 }}>
            <ComponentLoader />
          </Box>
        ) : (
          <>
            <Box sx={{ width: '100%', position: 'relative' }}>
              <Paper
                sx={{
                  width: '100%',
                  p: 0
                }}>
                {!!get(props, 'indicator.sum', null) && !!get(props, 'indicator.name', null) && (
                  <Box
                    sx={{
                      width: '100%',
                      maxWidth: '300px',
                      my: 1
                    }}>
                    <AggregateCard
                      icon={props.name === 'Sales Overview' ? props.linkTab !== 2 ? <MonetizationOn /> : <PointOfSale /> : <Rule />}
                      title={props.indicator.name}
                      value={props.indicator.sum}
                      valueSx={{
                        fontSize: '24px'
                      }}
                      bottomComp={
                        <Box sx={{ px: '20px', pb: '10px' }}>
                          {typeof props.indicator.delta === 'number' && (
                            <Tooltip
                              placement="top"
                              title={props.indicator.deltaTooltip}>
                              <Box
                                display="inline-flex"
                                alignItems={'center'}
                                gap="0.3rem"
                                sx={{
                                  background:
                                    props.indicator.deltaColor === 'error'
                                      ? deltaDownColors.background
                                      : props.indicator.deltaColor === 'zero'
                                        ? deltaZero.background
                                        : deltaUpColors.background,
                                  padding: '0.15rem 0.5rem',
                                  borderRadius: '5rem'
                                }}>
                                <>
                                  {props.indicator.delta < 0 && (
                                    <TrendingDownIcon
                                      sx={{
                                        color:
                                          props.indicator.deltaColor === 'error'
                                            ? deltaDownColors.color
                                            : props.indicator.deltaColor === 'zero'
                                              ? deltaZero.color
                                              : deltaUpColors.color
                                      }}
                                      fontSize="small"
                                    />
                                  )}
                                  {props.indicator.delta > 0 && (
                                    <TrendingUpIcon
                                      sx={{
                                        color:
                                          props.indicator.deltaColor === 'error'
                                            ? deltaDownColors.color
                                            : props.indicator.deltaColor === 'zero'
                                              ? deltaZero.color
                                              : deltaUpColors.color
                                      }}
                                      fontSize="small"
                                    />
                                  )}
                                  {typeof props.indicator.delta === 'number' && (
                                    <Typography
                                      variant="subtitle2"
                                      sx={{
                                        color:
                                          props.indicator.deltaColor === 'error'
                                            ? deltaDownColors.color
                                            : props.indicator.deltaColor === 'zero'
                                              ? deltaZero.color
                                              : deltaUpColors.color,
                                        fontSize: '0.6rem'
                                      }}>
                                      {formatNumber(props.indicator.delta, { maxFractionDigits: 2 })}%
                                    </Typography>
                                  )}
                                </>
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                      }
                    />
                  </Box>
                )}
                {hasCurrData && hasPrevData && (
                  <Box mb="16px">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(e) => setChecked(e.target.checked)}
                        />
                      }
                      label="Compare the date you chose with the previous one"
                    />
                  </Box>
                )}
                {props.graphData.length === 0 ? (
                  <EmptyScreen
                    NoDataImg={NoDataImg}
                    labels={emptyScreenMessages.emptyGraph}
                  />
                ) : (
                  <>
                    {((props.indicator.startDate && props.indicator.endDate) || (props.indicator.startDate2 && props.indicator.endDate2)) && (
                      <DateRangeDisplay
                        startOne={props.indicator.startDate}
                        endOne={props.indicator.endDate}
                        checked={!hasCurrData && hasPrevData ? true : hasCurrData ? hasPrevData && checked : false}
                        startTwo={moment(props.indicator.startDate2).format('D MMM')}
                        endTwo={moment(props.indicator.endDate2).format('D MMM')}
                      />
                    )}
                    <BarChart
                      data={props.graphData}
                      bars={bars}
                      xAxis={{
                        dataKey: 'id',
                        interval: tickInterval,
                        tickSize: 12,
                        tick: ({ x, y, payload }) => {
                          return (
                            <g transform={`translate(${x},${y})`}>
                              <text
                                x={0}
                                y={0}
                                dy={12}
                                textAnchor="middle"
                                fontWeight={600}
                                fontSize={12}
                                fill={primaryBarColor}>
                                {((checked && hasPrevData) || (!hasCurrData && hasPrevData)) && (
                                  <>
                                    <tspan fill={secondaryTextColor}>
                                      {get(
                                        props.graphData.find((item: any) => item.id === payload.value),
                                        'prevDate'
                                      )}
                                    </tspan>
                                    <tspan fill="#000">&nbsp; | &nbsp;</tspan>
                                  </>
                                )}
                                {hasCurrData && (
                                  <tspan>
                                    {get(
                                      props.graphData.find((item: any) => item.id === payload.value),
                                      'currentDate'
                                    )}
                                  </tspan>
                                )}
                              </text>
                            </g>
                          )
                        }
                      }}
                      options={{
                        cartesianGrid: true,
                        tooltip: true
                      }}
                      configs={{
                        barChartConfig: {
                          barCategoryGap: checked ? '25%' : '30%'
                        },
                        tooltipConfig: {
                          cursor: {
                            fill: '#EEE8'
                          },
                          content: (
                            <CustomTooltip
                              checked={checked}
                              name={props.name === 'Sales Overview' ? (props.linkTab !== 2 ? 'Sales' : 'Orders') : 'Errors'}
                              showDollar={props.name === 'Sales Overview' && props.linkTab !== 2}
                            />
                          )
                        }
                      }}
                    />
                  </>
                )}
                {props.type && props.message && (
                  <ErrorRateMessage
                    type={props.type}
                    category={props.message}
                  />
                )}
              </Paper>
            </Box>
          </>
        ))}

      {props.name === 'Error Overview' ? (
        <TabPanel
          index={1}
          value={value}>
          <HostKitchenTable tab={props.linkTab - 1} />
        </TabPanel>
      ) : (
        <TabPanel
          index={1}
          value={value}>
          <HostKitchenTable2 tab={props.linkTab - 1} />
        </TabPanel>
      )}
      {props.name === 'Error Overview' ? (
        <TabPanel
          index={2}
          value={value}>
          <VirtualBrandTable tab={props.linkTab - 1} />
        </TabPanel>
      ) : (
        <TabPanel
          index={2}
          value={value}>
          <VirtualBrandTable2 tab={props.linkTab - 1} />
        </TabPanel>
      )}
      {props.name === 'Error Overview' ? (
        <TabPanel
          index={3}
          value={value}>
          <PlatformChart
            chartClickable={props.chartClickable}
            tab={props.linkTab - 1}
          />
        </TabPanel>
      ) : (
        <TabPanel
          index={3}
          value={value}>
          <PlatformChart2 tab={props.linkTab - 1} />
        </TabPanel>
      )}
    </Paper>
  )
}

export default SalesOverview
