const schemaError = "Failed to execute 'transaction' on 'IDBDatabase'"
const VersionError = 'VersionError'

export function track(action: string, category: string, label?: string, value?: number, nonInteraction?: boolean, transport?: 'beacon' | 'xhr' | 'image') {
  // if (navigator.onLine) {
  //   ReactGA.event({
  //     category,
  //     action,
  //     label,
  //     value,
  //     nonInteraction, // optional, true/false
  //     transport // optional, beacon/xhr/image
  //   })
  // }
}
