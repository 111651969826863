import React, { createContext, useContext, useMemo } from 'react'
// import { track } from 'src/utils/analytics/analytics'
import { useSnackData } from 'src/context/SnackContext'
import { get } from 'src/utils/config/lodashUtils'
import { useLoadingData } from './LoadingContext'

interface ErrorContentInterface {
  handleError(error: string): void
  asyncWrapper(fn: any): Promise<void>
  openError: (text: string) => void
  openSuccess: (text: string) => void
  handleAsync(fn: () => Promise<any>): Promise<any>
}

export const schemaError = "Failed to execute 'transaction' on 'IDBDatabase'"
export const VersionError = 'VersionError'
export const databaseClosedError = 'DatabaseClosedError'

const initialState = {} as ErrorContentInterface

const ErrorContext = createContext<ErrorContentInterface>(initialState)

export const useErrorData = () => useContext(ErrorContext)

const ErrorContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { setSnack, openSuccess, openError } = useSnackData()
  const { setBackdropLoading } = useLoadingData()

  function handleError(error: string) {
    if (error && (typeof error === 'string' || typeof error === 'object')) {
      if (typeof error === 'object') setSnack({ type: 'error', open: true, text: get(error, 'message', error) })
      else setSnack({ type: 'error', open: true, text: error })
    }
  }
  async function asyncWrapper(fn: any) {
    try {
      return await fn
    } catch (error: any) {
      handleError(error.message)
    }
  }
  async function handleAsync(fn: () => Promise<any>) {
    try {
      const result = await fn()
      setBackdropLoading(false)
      return result
    } catch (error: any) {
      handleError(error.message)
      setBackdropLoading(false)
    }
  }

  const contextValue = useMemo(
    () => ({ handleError, asyncWrapper, openError, openSuccess, handleAsync }),
    [handleError, asyncWrapper, openError, openSuccess, handleAsync]
  )

  return <ErrorContext.Provider value={contextValue}>{children}</ErrorContext.Provider>
}

export default ErrorContextProvider
