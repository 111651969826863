import { Box, Skeleton, Stack, SvgIconTypeMap, Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { get } from 'src/utils/config/lodashUtils'

interface ListCompProps {
  bgcolor: string
  listIndexBgColor: string
  listIndexColor: string
  headingColor: string
  heading: string
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
  listItems: React.ReactNode[]
  loading?: boolean
}

export default function ListComp(props: ListCompProps) {
  const { Icon, loading } = props
  return (
    <Box
      sx={{
        px: '20px',
        py: '20px',
        bgcolor: props.bgcolor,
        borderRadius: '4px'
      }}>
      <Stack
        direction="row"
        alignItems="center"
        gap="15px">
        <Box sx={{ color: props.headingColor, fontSize: '18px', display: 'flex' }}>
          {loading ? (
            <Skeleton
              height={'18px'}
              variant="circular"
            />
          ) : (
            <Icon
              fontSize="inherit"
              color="inherit"
            />
          )}
        </Box>
        {loading ? (
          <Skeleton
            height={'14px'}
            width={'100%'}
          />
        ) : (
          <Typography
            color={props.headingColor}
            fontSize="14px"
            lineHeight="14px"
            fontWeight={600}>
            {props.heading}
          </Typography>
        )}
      </Stack>
      <Box sx={{ mt: '20px' }}>
        <table>
          <tbody>
            {loading ? (
              <Stack direction={'row'}>
                <tr>
                  <td style={{ paddingTop: '20px', verticalAlign: 'top' }}>
                    <Box
                      sx={{
                        width: '100%',
                        px: '3px',
                        bgcolor: props.listIndexBgColor
                      }}>
                      <Skeleton width={'18px'} />
                    </Box>
                  </td>
                  <td style={{ paddingTop: '20px', paddingLeft: '15px', width: '100%' }}>
                    <Typography
                      color="black"
                      fontSize="12px"
                      lineHeight="17px">
                      <Skeleton width={200} />
                    </Typography>
                  </td>
                </tr>
              </Stack>
            ) : (
              (get(props, 'listItems', []) || []).map((text, index) => {
                const paddingTop = `${index === 0 ? 0 : 20}px`
                return (
                  <tr key={index}>
                    <td style={{ paddingTop, verticalAlign: 'top' }}>
                      <Box
                        sx={{
                          width: '100%',
                          px: '3px',
                          bgcolor: props.listIndexBgColor
                        }}>
                        <Typography
                          textAlign="center"
                          fontSize="12px"
                          lineHeight="17px"
                          color={props.listIndexColor}>
                          {index + 1}.
                        </Typography>
                      </Box>
                    </td>
                    <td style={{ paddingTop, paddingLeft: '15px' }}>
                      <Typography
                        color="black"
                        fontSize="12px"
                        lineHeight="17px">
                        {text}
                      </Typography>
                    </td>
                  </tr>
                )
              })
            )}
          </tbody>
        </table>
      </Box>
    </Box>
  )
}
