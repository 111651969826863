import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Bar, BarChart, Cell, ResponsiveContainer, Text, XAxis, YAxis } from 'recharts'
import { get } from 'src/utils/config/lodashUtils'
import './charts.css'

let ctx

export const measureText14HelveticaNeue = (text) => {
  if (!ctx) {
    ctx = document.createElement('canvas').getContext('2d')
    ctx.font = "14px 'Helvetica Neue"
  }

  return ctx.measureText(text).width
}

const BAR_AXIS_SPACE = 30

const SimpleBarChart = ({
  type = 'default',
  data,
  yKey,
  xKey,
  marginLeft = 150,
  yAxisLabels = true,
  barSize = 32,
  heightConst = 50,
  chartClickable = false
}) => {
  const navigate = useNavigate()

  const navigateToSubCategories = (category) => {
    if (!chartClickable) return
    if (category) {
      const path =
        get(category, 'subcategory', get(category, 'key', '')).toLowerCase() === 'cancelled'
          ? 'cancelled-orders'
          : get(category, 'subcategory', get(category, 'key', '')).toLowerCase() === 'inaccurate'
            ? 'inaccurate-orders'
            : 'missed-orders'
      navigate(`/errors/${path}`, { state: { subcategory: category.name } })
    }
  }

  const YAxisLeftTick = ({ y, payload }) => {
    const endPoint = `/errors/` + payload.value.toLowerCase().split(' ').join('-')
    const category = type === 'default' ? data.find((e) => e.name === payload.value) : null
    return (
      <a
        href={type === 'default' ? null : endPoint}
        className="link_bar">
        <Text
          onClick={() => navigateToSubCategories(category)}
          x={0}
          y={y}
          textAnchor="start"
          verticalAnchor="middle"
          scaleToFit>
          {payload.value}
        </Text>
      </a>
    )
  }

  const maxTextWidth = useMemo(
    () =>
      data.reduce((acc, cur) => {
        const value = cur[yKey]
        const width = measureText14HelveticaNeue(value.toLocaleString())
        if (width > acc) {
          return width
        }
        return acc
      }, 0),
    [data, yKey]
  )

  const tickFormatter = (value, data) => {
    value = value.toLocaleString()
    if (data[0].symbol) {
      const symbol = data[0].symbol
      return symbol + value
    }
    return value
  }
  return (
    <ResponsiveContainer
      width={'100%'}
      height={heightConst * data.length}
      debounce={50}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{
          left: marginLeft,
          right: maxTextWidth + (BAR_AXIS_SPACE - 8)
        }}>
        <XAxis
          hide
          axisLine={false}
          type="number"
        />
        <YAxis
          yAxisId={0}
          dataKey={xKey}
          type="category"
          axisLine={false}
          tickLine={false}
          tick={YAxisLeftTick}
        />
        {yAxisLabels && (
          <YAxis
            orientation="right"
            yAxisId={1}
            dataKey={yKey}
            type="category"
            axisLine={false}
            tickLine={false}
            tickFormatter={(value) => tickFormatter(value, data)}
            mirror
            tick={{
              transform: `translate(${maxTextWidth + BAR_AXIS_SPACE}, 0)`
            }}
          />
        )}
        <Bar
          className="customBarStyle"
          onClick={(dataValue) => navigateToSubCategories(dataValue)}
          dataKey={yKey}
          minPointSize={2}
          barSize={barSize}>
          {data.map((d, idx) => {
            return (
              <Cell
                key={d[xKey]}
                fill={d.color}
              />
            )
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default SimpleBarChart
