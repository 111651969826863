import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { debounce, get } from 'src/utils/config/lodashUtils'

import { Label, LabelList, Legend, Pie, PieChart, Tooltip } from 'recharts'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import { usePayoutsBreakdownDataContext } from '../utils/context/PayoutsBreakdownDataContext'

interface DonutChartProps {
  data: {
    label: string
    value: number
    color: string
    textColor: string
    hide?: boolean
    tooltipLabel?: string
    // percentage?: number
  }[]
  threePFeePerc?: number
  centerLabelFormatter?: (label: string) => string
  centerValueFormatter?: (value: number) => string
  loading?: boolean
}

export default function DonutChart(props: DonutChartProps) {
  const { data } = props
  const { fontSizes } = usePayoutsBreakdownDataContext()
  const pieChartWrapper = useRef<HTMLDivElement | null>(null)
  const [chartDimensions, setChartDimensions] = useState({ width: 0, height: 0 })

  const { chartHeight, chartWidth, chartGap, chartInnerRadius, chartOuterRadius } = useMemo(() => {
    const size = Math.min(chartDimensions.width, chartDimensions.height)
    const chartWidth = size || 400
    const chartHeight = size || 400
    const chartGap = 0
    const chartThickness = size * 0.12
    const chartOuterRadius = (size - 2 * chartGap) / 3
    const chartInnerRadius = chartOuterRadius - chartThickness
    return {
      chartWidth,
      chartHeight,
      chartGap,
      chartOuterRadius,
      chartInnerRadius
    }
  }, [chartDimensions])

  const rechartData = useMemo(() => {
    const visibleData = data.filter((e) => !e.hide)
    return visibleData.map((e) => ({
      name: e.label,
      value: e.value,
      fill: e.color,
      textColor: e.textColor,
      tooltipLabel: e.tooltipLabel
    }))
  }, [data])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(
      debounce((entries: ResizeObserverEntry[]) => {
        entries.forEach((entry) => {
          const { width, height } = entry.contentRect
          setChartDimensions({ width, height })
        })
      }, 150)
    )
    if (pieChartWrapper.current) {
      resizeObserver.observe(pieChartWrapper.current)
    }
    return () => {
      resizeObserver.disconnect()
    }
  }, [pieChartWrapper.current])

  return (
    <Box
      ref={pieChartWrapper}
      sx={{
        width: '100%',
        aspectRatio: '1 / 1',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      {props.loading ? (
        <Stack
          direction="row"
          spacing={2}
          sx={{ width: '100%', height: '100%' }}>
          <Box
            sx={{
              width: '60%',
              height: '60%',
              padding: '12px'
            }}>
            <Skeleton
              variant="circular"
              width="100%"
              height="100%"
            />
          </Box>
          <Stack style={{ width: '40%', marginTop: '32px' }}>
            <Skeleton
              animation="wave"
              height={24}
              width="80%"
              style={{ marginBottom: 2 }}
            />
            <Skeleton
              animation="wave"
              height={24}
              width="80%"
              style={{ marginBottom: 2 }}
            />
            <Skeleton
              animation="wave"
              height={24}
              width="80%"
            />
          </Stack>
        </Stack>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'grid',
            placeItems: 'center'
          }}>
          <PieChart
            width={chartWidth}
            height={chartHeight}
            style={{
              margin: 'auto'
            }}>
            <Pie
              data={rechartData}
              dataKey="value"
              nameKey="name"
              cx="80%"
              cy="50%"
              innerRadius={chartInnerRadius}
              outerRadius={chartOuterRadius}>
              <Label
                value="Any value"
                position="center"
                content={(params) => {
                  const {
                    viewBox: { cx, cy, innerRadius }
                  } = params
                  return (
                    <foreignObject
                      x={cx - innerRadius / 2}
                      y={cy - innerRadius / 2}
                      width={innerRadius}
                      height={innerRadius}>
                      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography
                          fontSize="16px"
                          fontWeight={400}>
                          3P Fee
                        </Typography>
                        <Typography
                          fontSize="26px"
                          fontWeight={400}>
                          {formatNumber(get(props, 'threePFeePerc', 0), {
                            maxFractionDigits: 0
                          })}
                          %
                        </Typography>
                      </Box>
                    </foreignObject>
                  )
                }}
              />
            </Pie>
            <LabelList />
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="top"
              formatter={(name, entry, index) => {
                const percentage = get(entry, 'payload.percent', 0) * 100
                const value = get(entry, 'payload.value', 0)
                return `${name}: ${formatNumber(percentage, { maxFractionDigits: 1 })}% (${formatCurrency(value, {
                  maxFractionDigits: 0,
                  notation: 'compact'
                })})`
              }}
              content={(props) => {
                const { payload } = props
                return (
                  <ul>
                    {payload.map((entry, index) => {
                      const percentage = get(entry, 'payload.percent', 0) * 100
                      const name = entry.value
                      const value = get(entry, 'payload.value', 0)
                      const color = entry.color
                      return (
                        <li
                          key={`item-${index}`}
                          style={{ margin: '5px 0px', display: 'flex', alignItems: 'center', gap: '15px' }}>
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10">
                            <rect
                              x={0.5}
                              y={0.5}
                              width={9}
                              height={9}
                              fill={color}
                            />
                          </svg>
                          <Typography
                            fontSize={fontSizes['12'] + 'px'}
                            color="#696C80">
                            {name}: {formatNumber(percentage, { maxFractionDigits: 1 })}% (
                            {formatCurrency(value, {
                              maxFractionDigits: 1,
                              notation: 'compact'
                            })}
                            )
                          </Typography>
                        </li>
                      )
                    })}
                  </ul>
                )
              }}
              wrapperStyle={{
                fontSize: '12px',
                color: 'rgb(105, 108, 128)',
                paddingLeft: '24px'
              }}
            />
            <Tooltip
              formatter={(value, name, props) => {
                return [
                  `${formatCurrency(value, {
                    maxFractionDigits: 2
                    // notation: 'compact'
                  })}`,
                  name
                ]
              }}
            />
          </PieChart>
        </Box>
      )}
    </Box>
  )
}
