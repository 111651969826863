import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import CustomMaterialTableWrapper from 'src/components/CustomMaterialTableWrapper/CustomMaterialTableWrapper'
import PlatformLogo from 'src/components/PlatformLogo'
import { filterNames } from 'src/utils/config/config'
import { formatNumber } from 'src/utils/functions'
import { useTransactionsBreakdownDataContext } from '../utils/context/TransactionsBreakdownDataContext'

interface ChannelFulfillmentDataTableProps {}

export default function ChannelFulfillmentDataTable(props: ChannelFulfillmentDataTableProps) {
  const { channelFulfillmentData, channelFulfillmentDataLoading } = useTransactionsBreakdownDataContext()

  const tableData = useMemo(() => {
    if (!Array.isArray(channelFulfillmentData)) return []
    const total = channelFulfillmentData.reduce((acc, curr) => acc + (curr?.values || 0), 0)
    if (total === 0) return channelFulfillmentData.map(e => ({ ...e, percentage: 0 }))
    return channelFulfillmentData.map((e) => ({
      ...e,
      percentage: (e?.values / total) * 100
    }))
  }, [channelFulfillmentData])

  return (
    <>
      <Box>
        <CustomMaterialTableWrapper
          isLoading={channelFulfillmentDataLoading}
          data={tableData}
          columns={[
            {
              title: 'Channel',
              field: 'channel',
              render: (rowData) => {
                return (
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="5px">
                    <Box>
                      <PlatformLogo
                        shortImg
                        platformName={rowData.marketplace}
                        height="20px"
                      />
                    </Box>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}>
                      {rowData?.channel?.replaceAll('_', ' ')}
                    </Typography>
                  </Stack>
                )
              }
            },
            {
              title: 'Fulfillment',
              field: 'fulfillment',
              render: (rowData) => {
                return <Typography variant="subtitle2">{rowData?.fulfillment?.replaceAll('_', ' ')}</Typography>
              }
            },
            {
              title: 'Sales',
              field: 'values',
              alignType: 'currency'
            },
            {
              title: 'Percentage',
              field: 'percentage',
              alignType: 'currency',
              render: (rowData) => formatNumber(rowData.percentage, { maxFractionDigits: 2 }) + '%'
            }
          ]}
          options={{
            export: true,
            sortable: true,
            search: true,
            pagination: true,
            initialOrderBy: 'descending',
            initialSortableOption: 'percentage',
            extraExportColumns: [
              {
                title: filterNames.vb_platform,
                field: 'marketplace'
              }
            ]
          }}
        />
      </Box>
    </>
  )
}
