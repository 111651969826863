import { Box } from '@mui/material'
import { useState } from 'react'
import { CampaignDetailPaginatedV3 } from 'src/services/openApi'
import CampaignDetailDrawer from './CampaignDetailDrawer'
import CampaignTable from './CampaignTable'

export default function CampaignMetricsComp() {
  const [campaignDetails, setCampaignDetails] = useState<CampaignDetailPaginatedV3 | null>(null)

  return (
    <>
      {/* <CampaignOverview
      // showRecentlyAdded={showRecentlyAdded}
      // setShowRecentlyAdded={setShowRecentlyAdded}
      /> */}

      <Box>
        <CampaignTable
          onCampaignRowClick={setCampaignDetails}
          // showRecentlyAdded={showRecentlyAdded}
          // setShowRecentlyAdded={setShowRecentlyAdded}
        />
        <CampaignDetailDrawer
          open={campaignDetails !== null}
          campaignDetails={campaignDetails}
          onClose={() => setCampaignDetails(null)}
        />
      </Box>
    </>
  )
}
