import { FileDownloadOutlined, KeyboardArrowDownRounded } from '@mui/icons-material'
import { Box, Button, Collapse, Divider, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import { useMemo, useState } from 'react'
import CustomMaterialTableWrapper from 'src/components/CustomMaterialTableWrapper/CustomMaterialTableWrapper'
import InfoIcon from 'src/components/Icon/InfoIcon'
import PushHistoryIcon from 'src/components/Icon/PushHistoryIcon'
import PlatformLogo from 'src/components/PlatformLogo'
import { useSnackData } from 'src/context/SnackContext'
import { AccountingCadenceConfig, DefaultService } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'
import { get } from 'src/utils/config/lodashUtils'
import { pluralize } from 'src/utils/functions/pluralize'
import { JournalPushStatus, JournalPushStatusMap, PushHistoryData } from '../AccountingHistory'

interface PushHistoryItemProps {
  data: PushHistoryData
  loading: boolean
  cadences: AccountingCadenceConfig[]
  cadencesLoading: boolean
}

const PushHistoryItem = ({ data, loading, cadences, cadencesLoading }: PushHistoryItemProps) => {
  const { openError, setDownloadSnack } = useSnackData()

  const [expanded, setExpanded] = useState(false)

  const { logs, failedCount, successCount, inProgressCount, skippedCount } = useMemo(() => {
    const logs: typeof data.logs = get(data, 'logs', [])
    const failedCount = logs.filter((log) => log.status === JournalPushStatus.FAILURE).length
    const successCount = logs.filter((log) => log.status === JournalPushStatus.SUCCESS).length
    const inProgressCount = logs.filter((log) => log.status === JournalPushStatus.IN_PROGRESS).length
    const skippedCount = logs.filter((log) => log.status === JournalPushStatus.SKIPPED).length

    return {
      logs,
      failedCount,
      successCount,
      inProgressCount,
      skippedCount
    }
  }, [data])

  return (
    <Box
      border="1px solid #E6E6E6"
      borderRadius="4px">
      {loading ? (
        <Box
          px={2}
          py={1.5}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%">
          <Box
            display="flex"
            gap={1.5}>
            <Skeleton
              variant="circular"
              width={20}
              height={20}
            />

            <Box
              display="flex"
              flexDirection="column"
              gap={1}>
              <Skeleton
                variant="rounded"
                width={400}
                height={20}
              />
              <Box
                display="flex"
                alignItems="center"
                height="25px"
                gap={2}>
                <Skeleton
                  variant="rounded"
                  width="200px"
                  height="100%"
                />
                <Skeleton
                  variant="rounded"
                  width="200px"
                  height="100%"
                  sx={{ borderRadius: '15px' }}
                />
              </Box>
            </Box>
          </Box>

          <Skeleton
            variant="rounded"
            width="120px"
            height="17px"
          />
        </Box>
      ) : (
        <Box
          px={2}
          py={1.5}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            cursor: 'pointer'
          }}
          onClick={() => setExpanded((prev) => !prev)}>
          <Box
            display="flex"
            gap={1.5}>
            <PushHistoryIcon sx={{ height: '20px' }} />

            <Box
              display="flex"
              flexDirection="column"
              gap={1}>
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                height="20px">
                <Typography
                  fontSize={14}
                  color="#000"
                  lineHeight="initial">
                  Push attempt at <span style={{ fontWeight: 600 }}>{data.date}</span>
                </Typography>
                <KeyboardArrowDownRounded sx={{ color: '#000', fontSize: '20px', transform: expanded && 'rotate(180deg)', transition: '0.2s ease' }} />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                height="25px"
                gap={2}>
                {cadencesLoading ? (
                  <>
                    <Skeleton
                      variant="rounded"
                      width="200px"
                      height="100%"
                    />
                    <Skeleton
                      variant="rounded"
                      width="200px"
                      height="100%"
                      sx={{ borderRadius: '15px' }}
                    />
                  </>
                ) : (
                  <>
                    <Box
                      px={1}
                      height="100%"
                      border="1px solid #E6E6E6"
                      borderRadius="4px"
                      display="flex"
                      alignItems="center">
                      <Typography
                        fontSize={12}
                        color="#000"
                        fontWeight={600}>
                        {cadences.find((c) => c.id === data.cadence_id)?.name ?? 'From'} |{' '}
                        {data.start_date && data.start_date ? `${moment(data.start_date).format('DD MMM')} - ${moment(data.end_date).format('DD MMM')}` : null}
                      </Typography>
                    </Box>
                    <Box
                      px={1}
                      height="20px"
                      bgcolor={data.push_user === 'Loop Auto Push' ? '#E0EBFF' : '#5600C61C'}
                      borderRadius="10px"
                      display="flex"
                      alignItems="center">
                      <Typography
                        fontSize={12}
                        color={data.push_user === 'Loop Auto Push' ? '#004DE5' : '#5600C6'}
                        lineHeight="initial">
                        {data.push_user === 'Loop Auto Push' ? 'Automated' : `Manually pushed by ${data.push_user}`}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            gap={1}>
            <Typography
              fontSize={12}
              lineHeight="initial"
              color="#000">
              {logs.length} {pluralize(filterNames.b_name, logs.length)}
            </Typography>

            {successCount > 0 && (
              <Box
                px={1}
                bgcolor={JournalPushStatusMap[JournalPushStatus.SUCCESS].secondaryColor}
                borderRadius="10px">
                <Typography
                  fontSize={12}
                  lineHeight="initial"
                  color={JournalPushStatusMap[JournalPushStatus.SUCCESS].color}>
                  {successCount}
                </Typography>
              </Box>
            )}
            {failedCount > 0 && (
              <Box
                px={1}
                bgcolor={JournalPushStatusMap[JournalPushStatus.FAILURE].secondaryColor}
                borderRadius="10px">
                <Typography
                  fontSize={12}
                  lineHeight="initial"
                  color={JournalPushStatusMap[JournalPushStatus.FAILURE].color}>
                  {failedCount}
                </Typography>
              </Box>
            )}
            {skippedCount > 0 && (
              <Box
                px={1}
                bgcolor={JournalPushStatusMap[JournalPushStatus.SKIPPED].secondaryColor}
                borderRadius="10px">
                <Typography
                  fontSize={12}
                  lineHeight="initial"
                  color={JournalPushStatusMap[JournalPushStatus.SKIPPED].color}>
                  {skippedCount}
                </Typography>
              </Box>
            )}
            {inProgressCount > 0 && (
              <Box
                px={1}
                bgcolor={JournalPushStatusMap[JournalPushStatus.IN_PROGRESS].secondaryColor}
                borderRadius="10px">
                <Typography
                  fontSize={12}
                  lineHeight="initial"
                  color={JournalPushStatusMap[JournalPushStatus.IN_PROGRESS].color}>
                  {inProgressCount}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}

      <Collapse in={expanded}>
        <Box
          px={2}
          pb={2}>
          <Divider sx={{ borderColor: '#E6E6E6' }} />

          <CustomMaterialTableWrapper
            isLoading={loading}
            data={logs}
            columns={[
              {
                title: filterNames.b_name + 's',
                field: 'b_name'
              },
              {
                title: pluralize(filterNames.vb_platform),
                field: 'platforms',
                render: (rowData) => {
                  const platforms = rowData?.platforms?.split('|') || []
                  return (
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      {platforms.map((platform, index) => {
                        return (
                          <Stack
                            key={platform}
                            direction="row"
                            alignItems="stretch">
                            <Box>
                              <PlatformLogo
                                shortImg
                                platformName={platform}
                                height="20px"
                              />
                            </Box>
                            {index < platforms.length - 1 && (
                              <Box>
                                <Divider
                                  orientation="vertical"
                                  sx={{ mx: '5px' }}
                                />
                              </Box>
                            )}
                          </Stack>
                        )
                      })}
                    </Stack>
                  )
                }
              },
              {
                title: 'Status',
                field: 'status',
                render: (rowData) => (
                  <Box
                    display="flex"
                    gap={1}
                    alignItems={'center'}>
                    <Box
                      py={0.5}
                      px={1.5}
                      borderRadius="14px"
                      fontSize={12}
                      color={get(JournalPushStatusMap, `${rowData.status}.color`, '')}
                      bgcolor={get(JournalPushStatusMap, `${rowData.status}.secondaryColor`, '')}>
                      {get(JournalPushStatusMap, `${rowData.status}.name`, '')}
                    </Box>
                    {rowData.status === JournalPushStatus.FAILURE && (
                      <Tooltip
                        arrow
                        placement="right"
                        componentsProps={{
                          tooltip: {
                            style: {
                              color: 'white',
                              fontSize: 14,
                              fontWeight: 500,
                              background: 'black',
                              maxWidth: 400,
                              padding: '10px'
                            }
                          },
                          arrow: { style: { color: 'black' } }
                        }}
                        title={rowData.error}>
                        <Box sx={{ display: 'inline-flex' }}>
                          <InfoIcon sx={{ color: 'rgba(0,0,0,0.3)', width: '20px' }} />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                )
              },
              {
                title: 'Pre-push validation',
                field: 'override_tests',
                sortable: false,
                render: (rowData) => (
                  <Typography
                    fontSize={12}
                    color="#000"
                    fontWeight={600}
                    lineHeight="initial">
                    {rowData.override_tests === null ? '-' : rowData.override_tests === true ? 'No' : 'Yes'}
                  </Typography>
                )
              },
              {
                title: 'Pushed with overlap',
                field: 'override_push_overlap',
                sortable: false,
                render: (rowData) => (
                  <Typography
                    fontSize={12}
                    color="#000"
                    fontWeight={600}
                    lineHeight="initial">
                    {rowData.override_push_overlap == null ? '-' : rowData.override_push_overlap === true ? 'Yes' : 'No'}
                  </Typography>
                )
              },
              {
                title: 'Download',
                field: 'csv_link',
                sortable: false,
                render: (rowData) => {
                  return rowData?.csv_link ? (
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<FileDownloadOutlined />}
                      onClick={async () => {
                        setDownloadSnack({ status: 'start' })
                        try {
                          const refreshedUrl = await DefaultService.getSignedUrlGcpSignedUrlGet(rowData?.csv_link)

                          if (!refreshedUrl) throw new Error('No report found')

                          let a = document.createElement('a')
                          a.download = rowData.b_name
                          a.href = refreshedUrl
                          a.click()
                          setDownloadSnack({ status: 'complete' })
                        } catch (err) {
                          setDownloadSnack({ status: 'close' })
                          openError(err.message || "Couldn't download report")
                        }
                      }}>
                      Download
                    </Button>
                  ) : null
                }
              }
            ]}
            options={{
              search: true,
              pagination: true,
              sortable: true
            }}
          />
        </Box>
      </Collapse>
    </Box>
  )
}

export default PushHistoryItem
