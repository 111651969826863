import { TimelineModel } from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses
} from '@mui/lab'
import { Skeleton, Stack, Typography } from '@mui/material'
import moment from 'moment'
import DiamondCheckedIcon from 'src/components/Icon/DiamondCheckedIcon'
import LineStartDiamond from 'src/components/Icon/LineStartDiamondIcon'
import PlatformLogo from 'src/components/PlatformLogo'
import IconTooltip from 'src/components/Tooltip/IconTooltip'
import { get } from 'src/utils/config/lodashUtils'
import HeadingContainer from './helpers/HeadingContainer'

interface TimelineCompProps {
  timelineData?: TimelineModel
  loading?: boolean
  waitTimeData?: any
  collapsable?: boolean
}

export default function TimelineComp(props: TimelineCompProps) {
  const {
    timelineData = {
      received_ts: 'Order made by User',
      accepted_ts: 'Order accepted',
      prepared_ts: 'Order Ready',
      driver_arrival_ts: 'Driver arrived',
      dispatch_ts: 'Driver Pick up Time',
      delivered_ts: 'Order delivered'
    },
    loading
  } = props
  const color = 'rgba(230, 230, 230, 0.9)'
  const keyMap = {
    received_ts: 'Order made by User',
    accepted_ts: 'Order accepted',
    prepared_ts: 'Order ready',
    dispatch_ts: 'Driver Pick up Time',
    delivered_ts: 'Order delivered',
    driver_arrival_ts: 'Driver arrived'
  }

  return (
    <HeadingContainer
      title="Timeline"
      icon={<LineStartDiamond sx={{ fontSize: '24px', color: '#000' }} />}
      collapsable={props.collapsable}
      defaultOpen={true}>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            maxWidth: 150
          },
          p: 0
        }}>
        {timelineData &&
          Object.keys(timelineData)
            .filter((key) => timelineData[key])
            .map((data, index) => (
              <TimelineItem
                sx={{ pl: 0 }}
                key={data}>
                <TimelineOppositeContent
                  color="text.secondary"
                  sx={{
                    pl: 0,
                    textAlign: 'right',
                    display: 'flex',
                    fontSize: 14,
                    fontWeight: 400,
                    pr: 1,
                    justifyContent: 'flex-end',
                    pt: 0
                  }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ height: '30px' }}>
                    {loading ? (
                      <Skeleton
                        variant="text"
                        width="120px"
                        height="30px"
                      />
                    ) : (
                      <>
                        <Typography
                          fontWeight={400}
                          fontSize={'14px'}
                          color="rgba(0,0,0,1)"
                          sx={{ mr: 1 }}>
                          <span style={{ color: 'rgba(0, 0, 0, 0.3)' }}>{timelineData[data] && moment(timelineData[data]).format('DD MMM, ')}</span>{' '}
                          {timelineData[data] ? moment(timelineData[data]).format('hh:mmA') : 'No Data'}
                        </Typography>
                      </>
                    )}
                  </Stack>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      background: 'transparent',
                      boxShadow: 'none',
                      p: 0,
                      m: 0
                    }}>
                    <div
                      style={{
                        // width: 18,
                        // height: 18,
                        // borderRadius: '50%',
                        // background: 'rgba(101, 158, 171, 0.3)',
                        color,
                        paddingLeft: 1
                      }}>
                      <DiamondCheckedIcon
                        sx={{ fontSize: '30px' }}
                        color="inherit"
                      />
                    </div>
                  </TimelineDot>
                  {index !== Object.keys(timelineData).filter((key) => timelineData[key]).length - 1 && (
                    <TimelineConnector style={{ color, background: color, height: '35px' }} />
                  )}
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    pt: 0,
                    pl: 1,
                    color: '#000'
                  }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ height: '30px' }}>
                    {loading ? (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: '14px' }}
                        width="120px"
                        height="30px"
                      />
                    ) : (
                      <Typography
                        fontWeight={400}
                        fontSize={'14px'}
                        color="rgba(0,0,0,1)">
                        {keyMap[data]}
                      </Typography>
                    )}
                  </Stack>

                  {!loading && data === 'prepared_ts' && props.waitTimeData && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}>
                      <Typography
                        fontSize={12}
                        color="#0008">
                        Prep Time: {get(props, 'waitTimeData.prep_time', null)}
                      </Typography>
                      <IconTooltip
                        title="Prep time is an estimated time calculated by the marketplace"
                        boxSx={{
                          fontSize: '16px',
                          p: '2px'
                        }}
                        placement="right"
                      />
                    </Stack>
                  )}

                  {!loading && data === 'dispatch_ts' && props.waitTimeData && (
                    <Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}>
                        <Typography
                          fontSize={12}
                          color="#0008">
                          Wait time: {get(props, 'waitTimeData.mp_adwt', null)}
                        </Typography>

                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="2px">
                          <PlatformLogo
                            shortImg
                            platformName="Doordash"
                            height="16px"
                            width="16px"
                            boxSx={{
                              flex: 'unset',
                              display: 'flex'
                            }}
                          />
                          <PlatformLogo
                            shortImg
                            platformName="UberEats"
                            height="16px"
                            width="16px"
                            boxSx={{
                              flex: 'unset',
                              display: 'flex'
                            }}
                          />
                        </Stack>
                      </Stack>

                      {/* <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}>
                        <Typography
                          fontSize={12}
                          color="#0008">
                          Wait time: {get(props, 'waitTimeData.loop_adwt', null)}
                        </Typography>

                        <img
                          src={LoopLogo}
                          alt="Loop Logo"
                          height={14}
                          style={{ objectFit: 'contain' }}
                        />
                      </Stack> */}
                    </Stack>
                  )}
                </TimelineContent>
              </TimelineItem>
            ))}
      </Timeline>
    </HeadingContainer>
  )
}
