import { getFilterKeys } from 'src/components/NewFilterComponent/utils/filterUtils'
import { intersection } from 'src/utils/config/lodashUtils'
import { FilterGraph } from './FilterGraph'
import { FilterNode } from './FilterNode'

export const updateObserverVisibleNodesFromGraph = (observerKey: string, subjectSelectedNodes: FilterNode[], activeGraph: FilterGraph) => {
  const newVisibleNodes = new Set<FilterNode>()
  if (subjectSelectedNodes.length > 0 && subjectSelectedNodes.length < activeGraph.getAllPossibleNodesByKey(subjectSelectedNodes[0].key).length) {
    const validOptions = getValidOptions(observerKey, subjectSelectedNodes[0].key, activeGraph)
    const relatedOptions = new Set<FilterNode>()
    subjectSelectedNodes.forEach((node) => {
      node.relations[observerKey].forEach((relatedKeyValuePair) => {
        relatedOptions.add(relatedKeyValuePair.node)
      })
    })
    relatedOptions.forEach((relatedNode) => {
      if (validOptions.has(relatedNode)) {
        newVisibleNodes.add(relatedNode)
      }
    })
  } else {
    activeGraph.getAllPossibleNodesByKey(observerKey).forEach((relatedNode) => {
      newVisibleNodes.add(relatedNode)
    })
  }
  activeGraph.setVisible(observerKey, newVisibleNodes)
  // if the filter is single select and there's no overlap between visible and selected,
  // select the first option from the visible
  if (activeGraph.getSingleSelectByKey(observerKey) && activeGraph.getVisibleSelectedIntersectionNodes(observerKey).size === 0) {
    const [firstNewVisibleNode] = newVisibleNodes
    activeGraph.setSelected(observerKey, newVisibleNodes.size > 0 ? [firstNewVisibleNode] : [])
  }
}

export const isRelatedToAllSelectedFilters = (observerKey: string, observerNode: FilterNode, activeGraph: FilterGraph, subjectKey: string) => {
  const defaultFilterKeys = getFilterKeys()
  let result = true
  let commonSlugs = undefined
  defaultFilterKeys.forEach((key) => {
    // skip if result already false
    if (result === false) return
    // don't check relations of same key or against subject key, since it's already related
    if (key === observerKey || key === subjectKey) return
    // if any key does not have any selected nodes, new visible node is valid
    if (activeGraph.getSelectedNodesByKey(key).size === 0 || activeGraph.getSelectedNodesByKey(key).size === activeGraph.getAllPossibleNodesByKey(key).length)
      return

    let currentKeySlugs = []
    activeGraph.getSelectedNodesByKey(key).forEach((selectedNode) => {
      if (selectedNode.relations[observerKey].has(observerNode.value)) {
        const slugs = selectedNode.relations[observerKey].get(observerNode.value).slugs
        currentKeySlugs = [...currentKeySlugs, ...slugs]
      }
    })

    if (commonSlugs) {
      commonSlugs = intersection(commonSlugs, currentKeySlugs)
    } else {
      commonSlugs = [...currentKeySlugs]
    }

    // every key must have the new visible node in a relation, so using AND
    result = result && commonSlugs.length > 0
  })
  return result
}

// ? This function returns the set of observer options that are related to
// ? at least one selected node from filters other than subject filter
const getValidOptions = (observerKey: string, subjectKey: string, activeGraph: FilterGraph) => {
  const defaultFilterKeys = getFilterKeys()
  const validOptions = new Set<FilterNode>()
  const slugObserverNodeMap = new Map<string, FilterNode>()
  let commonSlugs = undefined
  defaultFilterKeys.forEach((key) => {
    /// for each filter key      // O(5)
    if (key === observerKey || key === subjectKey) return
    const slugs = []
    activeGraph.getSelectedNodesByKey(key).forEach((selectedNode) => {
      // O(1500)
      // for each selected node from ${key} filter
      selectedNode.relations[observerKey].forEach((relatedKeyValuePair) => {
        // O(1500)
        // for each relation of selected node
        slugs.push(...relatedKeyValuePair.slugs)
        relatedKeyValuePair.slugs.forEach((slug) => {
          // O(100)
          if (!slugObserverNodeMap.has(slug)) {
            slugObserverNodeMap.set(slug, relatedKeyValuePair.node)
          }
        })
      })
    })

    if (commonSlugs) {
      commonSlugs = intersection(commonSlugs, slugs)
    } else {
      commonSlugs = [...slugs]
    }
  })

  commonSlugs.forEach((slug) => {
    validOptions.add(slugObserverNodeMap.get(slug))
  })
  return validOptions
}
