import Paper from '@LoopKitchen/loop-ui/Paper'
import { Box } from '@mui/material'
import React from 'react'
import SimpleBarChart from '../Errors/components/SimpleBarChart'
// import useFilter from 'src/utils/hooks/useFilter'
import NoGraphDataImg from 'src/assets/images/no_data.png'
import EmptyScreen from 'src/components/EmptyScreen/EmptyScreen'
import ComponentLoader from 'src/components/Loading/ComponentLoader'
import { useAuth } from 'src/context/AuthContext'
import { useFilter } from 'src/context/FilterContext'
import { DefaultService } from 'src/services/openApi'
import { map } from 'src/utils/config/lodashUtils'
import { emptyScreenMessages } from 'src/utils/constants/emptyScreen'
import { measurePerformance } from 'src/utils/functions/performance'
import { ApiKpiErrorOverviewType } from './types'

interface PlatformChartProps {
  tab: number
  chartClickable?: boolean
}

const PlatformChart = (props: PlatformChartProps) => {
  const [graphData, setGraphData] = React.useState([])
  const { currentUser } = useAuth()
  const [graphDataLoading, setGraphDataLoading] = React.useState(true)
  const { getFilters, getFiltersV2 } = useFilter()
  const tabNames = ['', '_Missed', '_Cancelled', '_Inaccurate']
  const cacheResponse = React.useRef<{ [key: string]: ApiKpiErrorOverviewType }>({})

  React.useEffect(() => {
    cacheResponse.current = {}
  }, [...getFilters(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date'])])

  React.useEffect(() => {
    ;(async () => {
      setGraphDataLoading(true)
      try {
        const responseData =
          props.tab in cacheResponse.current
            ? cacheResponse.current[props.tab]
            : ((await measurePerformance(
                async () => {
                  return await DefaultService.getErrorOverviewKpiErrorOverviewPost({
                    ...getFiltersV2(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date'], true),
                    error_category_in: tabNames[props.tab].slice(1) // slicing '_Missed' to 'Missed'
                  })
                },
                {
                  procedure: 'data_visualization_metrics.procedure_error_overview_platform_',
                  email: currentUser.email,
                  org: currentUser.org
                }
              )) as ApiKpiErrorOverviewType)
        cacheResponse.current[props.tab] = responseData
        if (responseData) {
          setGraphData(
            map(responseData.error_platform, (item, index) => ({
              id: index + 1,
              name: item.platform,
              count: item.errors,
              color: '#196E82'
            }))
          )
          setGraphDataLoading(false)
        }
      } catch (error) {
        setGraphDataLoading(false)
        console.log(error)
      }
    })()
  }, [props.tab, currentUser, ...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])])
  return (
    <Paper>
      {graphDataLoading ? (
        <Box sx={{ width: '100%', textAlign: 'center', p: 3 }}>
          <ComponentLoader />
        </Box>
      ) : graphData && graphData.length === 0 ? (
        <EmptyScreen
          NoDataImg={NoGraphDataImg}
          labels={emptyScreenMessages.emptyGraph}
        />
      ) : (
        <SimpleBarChart
          chartClickable={props.chartClickable}
          data={graphData}
          xKey="name"
          yKey="count"
        />
      )}
    </Paper>
  )
}

export default PlatformChart
