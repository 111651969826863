import AreaChart from '@LoopKitchen/loop-ui/Charts/AreaChart'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Skeleton, Typography } from '@mui/material'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { get } from 'src/utils/config/lodashUtils'
import { numberAbbreviation } from 'src/utils/functions'
import ChartDot from './ChartDot'
import ChartMarkers from './ChartMarkers'
import ChartTooltip, { TooltipLabelsType } from './ChartTooltip'

interface SyncedChartProps<T extends object> {
  title: string
  showLegend?: boolean
  data: ({ date: string } & T)[]
  dataKey: string
  loading: boolean
  tooltipLabels: TooltipLabelsType
  syncId: string
  yAxisType?: 'percentage' | 'currency' | null
  parentWidth: number
  summaryData?: T
  onTitleClick?: () => void
  activeIndex: number
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>
  visibleTooltip: string
  setVisibleTooltip: React.Dispatch<React.SetStateAction<string>>
  aggregateData: any
}

export default function SyncedChart<T extends object>(props: SyncedChartProps<T>) {
  const {
    data,
    loading,
    dataKey,
    syncId,
    title,
    yAxisType,
    parentWidth,
    tooltipLabels,
    summaryData,
    onTitleClick,
    activeIndex,
    setActiveIndex,
    visibleTooltip,
    setVisibleTooltip,
    aggregateData
  } = props
  const lineRef = useRef(null)

  const [points, setPoints] = useState([])
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })

  const filteredData = useMemo(() => {
    if (!data) return []
    if (!aggregateData || aggregateData.length === 0) return data

    return aggregateData?.filter((item) => data.find((d) => d.date === item.date))
  }, [aggregateData, data])

  useEffect(() => {
    if (!points || points.length === 0 || !activeIndex || !parentWidth) return

    const coordinate = points[activeIndex]
    const x = coordinate?.x
    const y = coordinate?.y
    const tooltipWidth = 500
    const halfTooltipWidth = tooltipWidth / 2

    const point = {
      x:
        x + halfTooltipWidth + 32 > parentWidth
          ? parentWidth - tooltipWidth - 32
          : x - halfTooltipWidth > 0
            ? x - halfTooltipWidth
            : x - (x % halfTooltipWidth),
      y: y - 210 || 0
    }

    setTooltipPosition(point)
  }, [points, activeIndex, parentWidth])

  return (
    <Box sx={{ p: 2, pr: 3, border: '1px solid #E6E6E6E6', borderRadius: '4px' }}>
      <Box
        pl={1}
        mb={2}
        display="flex"
        flexDirection="column"
        gap={1}>
        <Box
          display="inline-flex"
          flexDirection="row"
          justifyContent="flex-start"
          onClick={onTitleClick}
          sx={{
            cursor: onTitleClick ? 'pointer' : null,
            '&:hover': {
              textDecoration: onTitleClick ? 'underline' : null
            }
          }}>
          <Typography
            fontSize="14px"
            color={onTitleClick ? 'rgb(25, 110, 130)' : '#000000'}
            fontWeight={600}>
            {title}
          </Typography>
          {onTitleClick && <ChevronRightIcon sx={{ fontSize: '18px', color: 'rgb(25, 110, 130)' }} />}
        </Box>

        {summaryData &&
          (loading ? (
            <Skeleton
              variant="rounded"
              width={100}
              height={30}
            />
          ) : (
            <Typography
              fontSize="24px"
              color="#000"
              fontWeight={600}>
              {numberAbbreviation[yAxisType ? yAxisType : 'default'](summaryData[dataKey])}
            </Typography>
          ))}
      </Box>

      <Box position="relative">
        {points[activeIndex] && (
          <ChartMarkers
            index={activeIndex}
            x={points[activeIndex]?.x}
            y={points[activeIndex]?.y}
            xValue={points[activeIndex]?.payload[dataKey]}
            yValue={points[activeIndex]?.payload?.date}
            yAxisType={yAxisType}
          />
        )}

        {loading ? (
          <Skeleton
            variant="rounded"
            width="100%"
            height={330}
          />
        ) : (
          <AreaChart
            width="100%"
            height={330}
            data={filteredData}
            areas={{
              ref: lineRef,
              dataKey: dataKey,
              fill: 'url(#myGradient)',
              strokeWidth: 1,
              activeDot: ({ cx, cy }) => (
                <ChartDot
                  cx={cx}
                  cy={cy}
                />
              ),
              onAnimationEnd: () => setPoints(get(lineRef, 'current.props.points', []))
            }}
            xAxis={{
              dataKey: 'date',
              tickFormatter: (value) => moment(value).format('DD MMM'),
              tick: { fontSize: 13, fill: '#425866' },
              interval: filteredData?.length > 10 ? Math.floor(filteredData?.length / 10) : 0
            }}
            yAxis={{
              width: 70,
              tickFormatter: (value) => numberAbbreviation[yAxisType ? yAxisType : 'default'](value),
              tick: { fontSize: 13, fill: '#425866' }
            }}
            options={{
              cartesianGrid: true,
              tooltip: true,
              referenceLines: true,
              legend: props.showLegend ?? true
            }}
            extraSvgElements={[
              <defs key={1}>
                <linearGradient
                  id="myGradient"
                  gradientTransform="rotate(90)"
                  stopColor="#196E82">
                  <stop
                    offset="30%"
                    stopColor="#196E82"
                  />
                  <stop
                    offset="70%"
                    stopColor="#84b8c4"
                  />
                  <stop
                    offset="100%"
                    stopColor="#FFFFFF"
                  />
                </linearGradient>
              </defs>
            ]}
            configs={{
              areaChartConfig: {
                syncId: syncId,
                onMouseMove: (e) => {
                  if (e.activeTooltipIndex !== undefined) setActiveIndex(e.activeTooltipIndex)
                  setVisibleTooltip(dataKey)
                },
                onMouseLeave: () => {
                  setActiveIndex(null)
                  setVisibleTooltip(null)
                },
                margin: { right: 30 }
              },
              cartesianGridConfig: {
                stroke: '#CFD8E0',
                strokeDasharray: '5 5'
              },
              tooltipConfig: {
                allowEscapeViewBox: { y: true },
                content: ({ active, payload }) =>
                  visibleTooltip === dataKey ? (
                    <ChartTooltip
                      active={active}
                      payload={payload}
                      tooltipLabels={tooltipLabels}
                      aggregateData={filteredData}
                      visibleTooltip={visibleTooltip}
                    />
                  ) : (
                    <></>
                  ),
                cursor: false,
                wrapperStyle: { zIndex: 1000 },
                position: tooltipPosition
              },
              referenceLinesConfig: [
                {
                  y: null,
                  x: filteredData[activeIndex]?.date,
                  stroke: '#010101',
                  strokeDasharray: '3 3',
                  strokeWidth: 1
                },
                { y: filteredData[activeIndex]?.[dataKey], stroke: '#010101', strokeDasharray: '3 3', strokeWidth: 1 }
              ],
              legendConfig: {
                verticalAlign: 'top',
                content: ({ payload }) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    gap={2}
                    mb={2}>
                    {payload?.map((data, index) => (
                      <Box
                        key={data.value + index}
                        display="flex"
                        alignItems="center"
                        gap={1}>
                        <Box
                          bgcolor={data.color}
                          width={10}
                          height={10}
                        />
                        <Typography
                          fontSize="14px"
                          color="#000"
                          fontWeight={400}
                          lineHeight="initial">
                          {tooltipLabels[data.value]?.label}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )
              }
            }}
          />
        )}
      </Box>
    </Box>
  )
}
