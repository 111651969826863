import { AxiosResponse } from 'axios'

import { auth, fetchUserOrgAndRole } from 'src/services/firebase'

async function latencyAnalysis<T>(endPoint: string, func: () => T) {
  const start = Date.now()
  const uid = auth?.currentUser?.uid
  const { org } = await fetchUserOrgAndRole(uid)
  const result: { config?: any; [key: string]: any } = await func()
  const service = endPoint || 'unknown'
  const end = Date.now()
  return result
}

export async function measurePerformance(
  fn: () => Promise<
    | {
        data?: any
        [key: string]: string
      }
    | AxiosResponse<any>
    | any
  >,
  obj: {
    [key: string]: string
  } = { type: 'performance' }
) {
  const endpoint = obj?.procedure
  // TODO: UNCOMMENT COMMENTED CODE TO SEE TIME DIFFERENCE
  // return await new Promise(async (res, rej) => {
  //     // let start = Date.now()
  //     let interval
  //     ( () => {
  //         let counter = 1
  //         interval = setInterval( () => {
  //             console.log("timer start")
  //             if ( counter > 3 ) {
  //                 clearInterval( interval )
  //                 track({
  //                     action: `'Sorry, it seems like there's a delay in getting the information. , '${JSON.stringify(obj)}`
  //                 })
  //                return rej("Sorry, it seems like there's a delay in getting the information. ")
  //             }
  //             // const end = Date.now()
  //             counter += 1
  //             // console.log( `${parseFloat( ( ( end - start ) / 1000 ).toString() ).toFixed( 2 )}s` )
  //         }, 1000 )
  //     } )()
  //     console.log("api call start")
  //     const response = await fn()
  //     clearInterval(interval)
  //     // const end = Date.now()
  //     // console.log( `end - ${parseFloat( ( ( end - start ) / 1000 ).toString() ).toFixed( 2 )}s` )
  //     res(response)
  // })
   
  return await new Promise(async (res, rej) => {
    try {
      // const start = Date.now()
      let timer
      ;(() => {
        timer = setTimeout(() => {
          clearTimeout(timer)
          return rej("Sorry, it seems like there's a delay in getting the information. ")
        }, 19000)
      })()

      const result: { config?: any; [key: string]: any } = await fn()
      // const response = await latencyAnalysis(endpoint, fn)
      clearTimeout(timer)
      // const end = Date.now()
      // const timeDiff = `${parseFloat(((end - start) / 1000).toString()).toFixed(
      //   2
      // )}s- '${JSON.stringify(obj)}`
      //   track({
      //     action: timeDiff,
      //     optionalData: {
      //       ...obj,
      //       type: 'performance',
      //       procedure: get(obj, 'procedure', '')
      //     },
      //     category: 'performance'
      //   })
      res(result)
    } catch (error) {
      console.log('error in performance: ', error)
      rej(error)
    }
  })
}
